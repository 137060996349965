define("@fleetbase/console/utils/calculate-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = calculatePercentage;

  function calculatePercentage(percentage, number) {
    return percentage / 100 * number;
  }
});