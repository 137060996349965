define("@fleetbase/console/routes/certifications", ["exports", "greenfreight-extension/routes/certifications"], function (_exports, _certifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _certifications.default;
    }
  });
});