define("@fleetbase/console/adapters/user", ["exports", "@fleetbase/console/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserAdapter extends _application.default {
    /**
     * Set the URL path for users endpoints
     *
     * @param {object} query
     * @return {String} originalUrl
     */
    urlForQueryRecord(query) {
      let originalUrl = super.urlForQueryRecord(...arguments);

      if (query.me) {
        delete query.me;
        return `${originalUrl}/me`;
      }

      return originalUrl;
    }

  }

  _exports.default = UserAdapter;
});