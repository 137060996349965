define("@fleetbase/console/transforms/raw", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RawTransform extends _transform.default {
    deserialize(serialized) {
      return serialized;
    }

    serialize(deserialized) {
      return deserialized;
    }

  }

  _exports.default = RawTransform;
});