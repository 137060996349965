define("@fleetbase/console/helpers/json-stringify", ["exports", "developers-console/helpers/json-stringify"], function (_exports, _jsonStringify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _jsonStringify.default;
    }
  });
  Object.defineProperty(_exports, "jsonStringify", {
    enumerable: true,
    get: function () {
      return _jsonStringify.jsonStringify;
    }
  });
});