define("@fleetbase/console/helpers/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function date() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return new Date(...args);
  });

  _exports.default = _default;
});