define("@fleetbase/console/serializers/entity", ["exports", "@fleetbase/console/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EntitySerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        // payload: { embedded: 'always' },
        destination: {
          embedded: 'always'
        },
        trackingNumber: {
          embedded: 'always'
        },
        driver: {
          embedded: 'always'
        },
        photo: {
          embedded: 'always'
        },
        facilitator: {
          embedded: 'always'
        },
        customer: {
          embedded: 'always'
        }
      };
    }

  }

  _exports.default = EntitySerializer;
});