define("@fleetbase/console/components/modals/option-prompt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          <div class="flex items-center justify-center p-3">
              <select class="form-select w-full" {{on "change" (fn @options.selectOption)}}>
                  <option selected disabled>
                      {{@options.title}}
                  </option>
                  {{#each @options.promptOptions as |promptOption|}}
                      <option value={{promptOption}} selected={{eq @options.selected promptOption}}>
                          {{humanize promptOption}}
                      </option>
                  {{/each}}
              </select>
          </div>
      </div>
      {{yield}}
  </Ui::Modal>
  */
  {
    "id": "T6gmRkzK",
    "block": "{\"symbols\":[\"promptOption\",\"@options\",\"@modalIsOpened\",\"@onConfirm\",\"@onDecline\",\"&default\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,3],[32,2],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex items-center justify-center p-3\"],[12],[2,\"\\n            \"],[11,\"select\"],[24,0,\"form-select w-full\"],[4,[38,3],[\"change\",[30,[36,2],[[32,2,[\"selectOption\"]]],null]],null],[12],[2,\"\\n                \"],[10,\"option\"],[14,\"selected\",\"\"],[14,\"disabled\",\"\"],[12],[2,\"\\n                    \"],[1,[32,2,[\"title\"]]],[2,\"\\n                \"],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,2,[\"promptOptions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[10,\"option\"],[15,2,[32,1]],[15,\"selected\",[30,[36,0],[[32,2,[\"selected\"]],[32,1]],null]],[12],[2,\"\\n                        \"],[1,[30,[36,1],[[32,1]],null]],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[18,6,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"humanize\",\"fn\",\"on\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/option-prompt.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});