define("@fleetbase/console/components/modals/select-payment-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          {{#each @options.paymentMethods as |paymentMethod|}}
              <a href="javascript:;" {{on "click" (fn @options.selectPaymentMethod paymentMethod)}} class="flex items-center justify-between px-3 py-2 shadow-sm rounded-md border border-gray-900 {{if (eq @options.selectedPaymentMethod.id paymentMethod.id) 'border-blue-500 shadow-outline-blue'}}">
                  <div class="flex items-center dark:text-gray-100">
                      <FaIcon @icon="cc-visa" class="mr-1" />
                      <span class="mr-2">Card ending in {{paymentMethod.last4}}</span>
                  </div>
              </a>
          {{/each}}
      </div>
  </Ui::Modal>
  
  */
  {
    "id": "ozU43wj8",
    "block": "{\"symbols\":[\"paymentMethod\",\"@options\",\"@modalIsOpened\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,3],[32,2],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,2,[\"paymentMethods\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"a\"],[24,6,\"javascript:;\"],[16,0,[31,[\"flex items-center justify-between px-3 py-2 shadow-sm rounded-md border border-gray-900 \",[30,[36,1],[[30,[36,0],[[32,2,[\"selectedPaymentMethod\",\"id\"]],[32,1,[\"id\"]]],null],\"border-blue-500 shadow-outline-blue\"],null]]]],[4,[38,3],[\"click\",[30,[36,2],[[32,2,[\"selectPaymentMethod\"]],[32,1]],null]],null],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"flex items-center dark:text-gray-100\"],[12],[2,\"\\n                    \"],[8,\"fa-icon\",[[24,0,\"mr-1\"]],[[\"@icon\"],[\"cc-visa\"]],null],[2,\"\\n                    \"],[10,\"span\"],[14,0,\"mr-2\"],[12],[2,\"Card ending in \"],[1,[32,1,[\"last4\"]]],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"fn\",\"on\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/select-payment-method.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});