define("@fleetbase/console/system/widgets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const widgets = [{
    title: 'Recent Orders',
    description: 'Allows users to view recent orders by an adjustable date interval such as days or weeks.',
    service: 'fleet-ops',
    component: 'widgets/recent-orders',
    width: 25,
    height: 425,
    options: [{
      title: 'Default date interval',
      description: 'Set the default date interval',
      value: '7 days'
    }]
  }, {
    title: 'Recent Transactions',
    description: 'Allows users to view recent order transactions by an adjustable date interval such as days or weeks.',
    service: 'fleet-ops',
    component: 'widgets/recent-transactions',
    width: 25,
    height: 425
  }, {
    title: 'Recent Issues',
    description: 'Allows users to view recent driver reported issues by an adjustable date interval such as days or weeks.',
    service: 'fleet-ops',
    component: 'widgets/recent-issues',
    width: 25,
    height: 425
  }, {
    title: 'Drivers Online',
    description: 'Allows users to view drivers online.',
    service: 'fleet-ops',
    component: 'widgets/drivers-online',
    width: 25,
    height: 425
  }, {
    title: 'Drivers In Tansit',
    description: 'Allows users to view drivers in transit.',
    service: 'fleet-ops',
    component: 'widgets/drivers-in-transit',
    width: 25,
    height: 425
  }, {
    title: 'Successful Orders',
    description: 'Allows users to view orders that have been completed successfully.',
    service: 'fleet-ops',
    component: 'widgets/successful-orders',
    width: 25,
    height: 425
  }, {
    title: 'Unuccessful Orders',
    description: 'Allows users to view orders that have been completed unsuccessfully.',
    service: 'fleet-ops',
    component: 'widgets/unsuccessful-orders',
    width: 25,
    height: 425
  }];
  var _default = widgets;
  _exports.default = _default;
});