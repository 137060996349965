define("@fleetbase/console/components/next/view-section-subheader", ["exports", "@fleetbase/ui/components/next/view-section-subheader"], function (_exports, _viewSectionSubheader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _viewSectionSubheader.default;
    }
  });
});