define("@fleetbase/console/services/modals-manager", ["exports", "ember-bootstrap-modals-manager/services/modals-manager"], function (_exports, _modalsManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ModalsManagerService = (_dec = Ember._tracked, (_class = class ModalsManagerService extends _modalsManager.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "arguments", _descriptor, this);
    }

    /**
     * Same as onClickConfirm but allows a handler to run then resolve by user
     *
     * @param {EbmmModalOptions} v
     */
    onClickConfirmWithDone(v) {
      const done = this.done.bind(this, v, 'onConfirm');

      if (this.options.confirm && typeof this.options.confirm === 'function') {
        const response = this.options.confirm(this, done); // hack keep dialog open until hold is true

        if (this.options.exitOverride && this.options.exitOverride === true) {
          return;
        }

        if (response && typeof response.then === 'function') {
          return response.finally(() => {
            done();
          });
        }

        return;
      }

      return done(v);
    }
    /**
     * Same as onClickDecline but allows a handler to run then resolve by user
     *
     * @param {EbmmModalOptions} v
     */


    onClickDeclineWithDone(v) {
      const done = this.done.bind(this, v, 'onDecline');

      if (this.options.decline && typeof this.options.decline === 'function') {
        const response = this.options.decline(this, done);

        if (response && typeof response.then === 'function') {
          return response.finally(() => {
            return done();
          });
        }

        return;
      }

      return done(v);
    }
    /**
     * Closes the modal and cleans up
     *
     * @void
     */


    done() {
      let v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let action = arguments.length > 1 ? arguments[1] : undefined;
      return new Promise(resolve => {
        var _this$modalDefer;

        const parentArguments = this.getArguments();
        const callback = Ember.get(this, `options.${action}`);
        const finalCallback = Ember.get(this, `options.onFinish`);
        Ember.set(this, 'modalIsOpened', false);
        (_this$modalDefer = this.modalDefer) === null || _this$modalDefer === void 0 ? void 0 : _this$modalDefer.resolve(v);
        this.clearOptions();
        this.clearArguments();
        resolve(true);

        if (typeof callback === 'function') {
          callback(...parentArguments, this.options);
        }

        if (typeof finalCallback === 'function') {
          finalCallback(...parentArguments, this.options);
        }
      });
    }
    /**
     * Arguments the modal service should track from options
     *
     * @var {Array}
     */


    /**
     * Get arguments based on service path array of argument names that exist in options
     *
     * @param {String} path
     * @return {Array}
     */
    getArguments() {
      let path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'options.args';
      const parentArguments = [];
      const currentArguments = Ember.get(this, path);

      if (this.arguments.length) {
        return this.arguments;
      }

      if (Ember.isArray(currentArguments)) {
        for (let i = 0; i < currentArguments.length; i++) {
          const arg = currentArguments[i];

          if (this.options[arg]) {
            parentArguments.pushObject(this.options[arg]);
          }
        }
      }

      return parentArguments;
    }
    /**
     * Sets arguments
     *
     * @param {String} path
     * @void
     */


    setArguments() {
      let _args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      this.arguments = Ember.isArray(_args) ? _args : [];
    }
    /**
     * Clears arguments
     *
     * @param {String} path
     * @void
     */


    clearArguments() {
      this.setArguments();
    }
    /**
     * Shows `confirm`-modal
     *
     * @method confirm
     * @param {object} options
     * @return {RSVP.Promise}
     */


    confirm(options) {
      return this.show(`modals/layout/confirm`, options);
    }
    /**
     * Shows `loading`-modal
     *
     * @method confirm
     * @param {object} options
     * @return {RSVP.Promise}
     */


    async displayLoader() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      await this.done();
      this.show(`modals/loading`, {
        title: 'Loading...',
        ...options
      });
    }
    /**
     * Shows `loading`-modal
     *
     * @method confirm
     * @param {object} options
     * @return {RSVP.Promise}
     */


    async userSelectOption(title) {
      let promptOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      let modalOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      await this.done();
      return new Promise(resolve => {
        const selected = null;
        this.show(`modals/option-prompt`, {
          title,
          promptOptions,
          selected,
          selectOption: event => {
            const {
              value
            } = event.target;
            this.setOption('selected', value);
          },
          confirm: () => {
            this.startLoading();
            const selected = this.getOption('selected');
            this.done();
            resolve(selected);
          },
          decline: () => {
            resolve(null);
          },
          ...modalOptions
        });
      });
    }
    /**
     * Retrieves an option
     *
     * @param {String} key
     * @return {Mixed}
     */


    getOption(key) {
      return Ember.get(this.options, key);
    }
    /**
     * Allows multiple options to be get
     *
     * @param {Object} options
     * @void
     */


    getOptions() {
      let props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return Ember.getProperties(this.options, props);
    }
    /**
     * Updates an option in the service
     *
     * @param {String} key
     * @param {Mixed} value
     * @void
     */


    setOption(key, value) {
      this.options = { ...this.options,
        [key]: value
      };
    }
    /**
     * Allows multiple options to be updated
     *
     * @param {Object} options
     * @void
     */


    setOptions() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.options = { ...this.options,
        ...options
      };
    }
    /**
     * Executes a function passed in options
     *
     * @void
     */


    invoke() {
      const params = [...arguments];
      const fn = params[0];

      if (typeof this.options[fn] === 'function') {
        params.shift();
        return this.options[fn](...params);
      }

      return null;
    }
    /**
     * Alias to start loading indicator on modal
     *
     * @void
     */


    startLoading() {
      this.setOption('isLoading', true);
    }
    /**
     * Alias to stop loading indicator on modal
     *
     * @void
     */


    stopLoading() {
      this.setOption('isLoading', false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "arguments", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class));
  _exports.default = ModalsManagerService;
});