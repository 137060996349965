define("@fleetbase/console/system/tooltips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const tooltips = [{
    title: 'Github Profile',
    description: 'Provides contextual information about a users github profile.',
    url: 'https://api.github.com/users/{username}',
    target: '[data-username]',
    content: '{response.name} works for {response.company}',
    service: 'developers-console'
  }];
  var _default = tooltips;
  _exports.default = _default;
});