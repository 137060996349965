define("@fleetbase/console/helpers/objkey", ["exports", "greenfreight-extension/helpers/objkey"], function (_exports, _objkey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _objkey.default;
    }
  });
  Object.defineProperty(_exports, "objkey", {
    enumerable: true,
    get: function () {
      return _objkey.objkey;
    }
  });
});