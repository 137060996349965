define("@fleetbase/console/helpers/service-name", ["exports", "fleet-ops/helpers/service-name"], function (_exports, _serviceName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _serviceName.default;
    }
  });
  Object.defineProperty(_exports, "serviceName", {
    enumerable: true,
    get: function () {
      return _serviceName.serviceName;
    }
  });
});