define("@fleetbase/console/utils/get-meta-field-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMetaFieldTypes;

  function getMetaFieldTypes() {
    return true;
  }
});