define("@fleetbase/console/models/user", ["exports", "@ember-data/model", "date-fns", "@fleetbase/console/utils/auto-serialize", "moment"], function (_exports, _model, _dateFns, _autoSerialize, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let User = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string', {
    defaultValue: 'https://s3.ap-southeast-1.amazonaws.com/flb-assets/static/no-avatar.png'
  }), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('raw'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('boolean'), _dec21 = (0, _model.attr)('date'), _dec22 = (0, _model.attr)('date'), _dec23 = (0, _model.attr)('date'), _dec24 = (0, _model.attr)('date'), _dec25 = (0, _model.attr)('date'), _dec26 = (0, _model.attr)('date'), _dec27 = Ember.computed('types'), _dec28 = Ember.computed('email_verified_at'), _dec29 = Ember.computed('phone_verified_at'), _dec30 = Ember.computed.not('isEmailVerified'), _dec31 = Ember.computed.not('isPhoneVerified'), _dec32 = Ember.computed('last_login'), _dec33 = Ember.computed('updated_at'), _dec34 = Ember.computed('updated_at'), _dec35 = Ember.computed('updated_at'), _dec36 = Ember.computed('created_at'), _dec37 = Ember.computed('created_at'), _dec38 = Ember.computed('created_at'), (_class = class User extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "uuid", _descriptor, this);

      _initializerDefineProperty(this, "public_id", _descriptor2, this);

      _initializerDefineProperty(this, "company_uuid", _descriptor3, this);

      _initializerDefineProperty(this, "avatar_uuid", _descriptor4, this);

      _initializerDefineProperty(this, "company_name", _descriptor5, this);

      _initializerDefineProperty(this, "avatar_url", _descriptor6, this);

      _initializerDefineProperty(this, "email", _descriptor7, this);

      _initializerDefineProperty(this, "password", _descriptor8, this);

      _initializerDefineProperty(this, "name", _descriptor9, this);

      _initializerDefineProperty(this, "phone", _descriptor10, this);

      _initializerDefineProperty(this, "date_of_birth", _descriptor11, this);

      _initializerDefineProperty(this, "timezone", _descriptor12, this);

      _initializerDefineProperty(this, "country", _descriptor13, this);

      _initializerDefineProperty(this, "ip_address", _descriptor14, this);

      _initializerDefineProperty(this, "slug", _descriptor15, this);

      _initializerDefineProperty(this, "type", _descriptor16, this);

      _initializerDefineProperty(this, "types", _descriptor17, this);

      _initializerDefineProperty(this, "status", _descriptor18, this);

      _initializerDefineProperty(this, "session_status", _descriptor19, this);

      _initializerDefineProperty(this, "is_admin", _descriptor20, this);

      _initializerDefineProperty(this, "phone_verified_at", _descriptor21, this);

      _initializerDefineProperty(this, "email_verified_at", _descriptor22, this);

      _initializerDefineProperty(this, "last_login", _descriptor23, this);

      _initializerDefineProperty(this, "deleted_at", _descriptor24, this);

      _initializerDefineProperty(this, "created_at", _descriptor25, this);

      _initializerDefineProperty(this, "updated_at", _descriptor26, this);

      _initializerDefineProperty(this, "emailIsNotVerified", _descriptor27, this);

      _initializerDefineProperty(this, "phoneIsNotVerified", _descriptor28, this);
    }

    /** @methods */
    deactivate() {
      const owner = Ember.getOwner(this);
      const fetch = owner.lookup(`service:fetch`);
      return new Promise((resolve, reject) => {
        return fetch.patch(`users/deactivate/${this.id}`).then(response => {
          this.session_status = 'inactive';
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    }

    activate() {
      const owner = Ember.getOwner(this);
      const fetch = owner.lookup(`service:fetch`);
      return new Promise((resolve, reject) => {
        return fetch.patch(`users/activate/${this.id}`).then(response => {
          this.session_status = 'active';
          resolve(response);
        }).catch(reject);
      });
    }

    removeFromCurrentCompany() {
      const owner = Ember.getOwner(this);
      const fetch = owner.lookup(`service:fetch`);
      return new Promise((resolve, reject) => {
        return fetch.delete(`users/remove-from-company/${this.id}`).then(resolve).catch(reject);
      });
    }

    resendInvite() {
      const owner = Ember.getOwner(this);
      const fetch = owner.lookup(`service:fetch`);
      return new Promise((resolve, reject) => {
        return fetch.post(`users/resend-invite`, {
          user: this.id
        }).then(response => {
          resolve(response);
        }).catch(reject);
      });
    }
    /** @computed */


    get typesList() {
      console.log(this.types);
      const types = Array.from(this.types);
      return types.join(', ');
    }

    get isEmailVerified() {
      return this.email_verified_at && (0, _moment.default)(this.email_verified_at).isValid();
    }

    get isPhoneVerified() {
      return this.phone_verified_at && (0, _moment.default)(this.phone_verified_at).isValid();
    }

    get lastLogin() {
      if (!this.last_login || !(0, _moment.default)(this.last_login).isValid()) {
        return 'Never';
      }

      return _moment.default.unix(this.last_login).format('LLL');
    }

    get updatedAgo() {
      return (0, _dateFns.formatDistanceToNow)(this.updated_at);
    }

    get updatedAt() {
      return (0, _dateFns.format)(this.updated_at, 'PPP p');
    }

    get updatedAtShort() {
      return (0, _dateFns.format)(this.updated_at, 'PP');
    }

    get createdAgo() {
      return (0, _dateFns.formatDistanceToNow)(this.created_at);
    }

    get createdAt() {
      return (0, _dateFns.format)(this.created_at, 'PPP p');
    }

    get createdAtShort() {
      return (0, _dateFns.format)(this.created_at, 'PP');
    }

    toJSON() {
      return (0, _autoSerialize.default)(this, ['serviceArea']);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "public_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "company_uuid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "avatar_uuid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "company_name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "avatar_url", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "password", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "date_of_birth", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "timezone", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "ip_address", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "types", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "session_status", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "is_admin", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "phone_verified_at", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "email_verified_at", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "last_login", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "typesList", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "typesList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isEmailVerified", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "isEmailVerified"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPhoneVerified", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "isPhoneVerified"), _class.prototype), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "emailIsNotVerified", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "phoneIsNotVerified", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lastLogin", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "lastLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAtShort", [_dec35], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAtShort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec36], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec37], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAtShort", [_dec38], Object.getOwnPropertyDescriptor(_class.prototype, "createdAtShort"), _class.prototype)), _class));
  _exports.default = User;
});