define("@fleetbase/console/utils/is-valid-coordinates", ["exports", "@fleetbase/console/utils/is-latitude", "@fleetbase/console/utils/is-longitude", "@fleetbase/console/utils/is-object"], function (_exports, _isLatitude, _isLongitude, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isValidCoordinates;

  /**
   * Validates coordinates
   *
   * ```
   * isValidCoordinates([0, 0]);
   * isValidCoordinates({lat: 0, lng: 0});
   * isValidCoordinates({latitude: 0, longitude: 0});
   * isValidCoordinates(0, 0);
   * ```
   *
   * @param {Array|Object|Integer} latitude
   * @param {Array|Object|Integer} longitude
   */
  function isValidCoordinates(latitude) {
    let longitude = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    let testLatitude, testLongitude;

    if (Ember.isArray(latitude) && longitude === null) {
      testLatitude = latitude[0];
      testLongitude = latitude[1];
    }

    if (!Ember.isArray(latitude) && (0, _isObject.default)(latitude) && longitude === null) {
      testLatitude = latitude.lat || latitude.latitude;
      testLongitude = latitude.lng || latitude.longitude;
    }

    if (longitude !== null) {
      testLatitude = latitude;
      testLongitude = longitude;
    }

    return (0, _isLatitude.default)(testLatitude) && (0, _isLongitude.default)(testLongitude);
  }
});