define("@fleetbase/console/serializers/questionnaire-table", ["exports", "greenfreight-extension/serializers/questionnaire-table"], function (_exports, _questionnaireTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _questionnaireTable.default;
    }
  });
});