define("@fleetbase/console/utils/get-length-units", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getLengthUnits;

  function getLengthUnits() {
    return [{
      name: 'Meter',
      value: 'm'
    }, {
      name: 'Millimeter',
      value: 'mm'
    }, {
      name: 'Centimeter',
      value: 'cm'
    }, {
      name: 'Decimeter',
      value: 'dm'
    }, {
      name: 'Kilometer',
      value: 'km'
    }, {
      name: 'Inch',
      value: 'in'
    }, {
      name: 'Foot',
      value: 'ft'
    }, {
      name: 'Yard',
      value: 'yd'
    }, {
      name: 'Astonomical Unit',
      value: 'AE'
    }, {
      name: 'Light Year',
      value: 'lj'
    }];
  }
});