define("@fleetbase/console/components/modals/set-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          <div class="flex px-3 py-2 mb-6 rounded-md shadow-sm bg-blue-200">
              <div>
                  <FaIcon @icon="info-circle" class="text-blue-900 mr-4" />
              </div>
              <p class="flex-1 text-sm text-blue-900 dark:text-blue-900">
                  Welcome to Fleetbase! Before you get started, set a new password to continue below.
              </p>
          </div>
  
          <Ui::InputGroup @name="New Password" @value={{@options.password}} @type="password" @inputClass="form-input-lg" @helpText="Enter a password at-least 8 characters to continue." />
          <Ui::InputGroup @name="Confirm Password" @value={{@options.password_confirmation}} @type="password" @inputClass="form-input-lg" @helpText="Enter a password at-least 8 characters to continue." />
      </div>
  </Ui::Modal>
  */
  {
    "id": "HOCqu42+",
    "block": "{\"symbols\":[\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,1],[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex px-3 py-2 mb-6 rounded-md shadow-sm bg-blue-200\"],[12],[2,\"\\n            \"],[10,\"div\"],[12],[2,\"\\n                \"],[8,\"fa-icon\",[[24,0,\"text-blue-900 mr-4\"]],[[\"@icon\"],[\"info-circle\"]],null],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"p\"],[14,0,\"flex-1 text-sm text-blue-900 dark:text-blue-900\"],[12],[2,\"\\n                Welcome to Fleetbase! Before you get started, set a new password to continue below.\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[8,\"ui/input-group\",[],[[\"@name\",\"@value\",\"@type\",\"@inputClass\",\"@helpText\"],[\"New Password\",[32,2,[\"password\"]],\"password\",\"form-input-lg\",\"Enter a password at-least 8 characters to continue.\"]],null],[2,\"\\n        \"],[8,\"ui/input-group\",[],[[\"@name\",\"@value\",\"@type\",\"@inputClass\",\"@helpText\"],[\"Confirm Password\",[32,2,[\"password_confirmation\"]],\"password\",\"form-input-lg\",\"Enter a password at-least 8 characters to continue.\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/set-password.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});