define("@fleetbase/console/components/modals/entity-meta-field-prompt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          <KeyInput @name="Meta Field Key" @value={{@options.keyName}} @onChange={{fn (mut @options.keyName)}} />
      </div>
      {{yield}}
  </Ui::Modal>
  */
  {
    "id": "Dv9qJ+t7",
    "block": "{\"symbols\":[\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\",\"&default\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,1],[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[8,\"key-input\",[],[[\"@name\",\"@value\",\"@onChange\"],[\"Meta Field Key\",[32,2,[\"keyName\"]],[30,[36,1],[[30,[36,0],[[32,2,[\"keyName\"]]],null]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[18,5,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/entity-meta-field-prompt.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});