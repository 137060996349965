define("@fleetbase/console/components/ui/date-time-input", ["exports", "@fleetbase/ui/components/ui/date-time-input"], function (_exports, _dateTimeInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dateTimeInput.default;
    }
  });
});