define("@fleetbase/console/models/port", ["exports", "@ember-data/model", "@fleetbase/console/utils/is-valid-coordinates", "@fleetbase/console/utils/extract-longitude", "@fleetbase/console/utils/extract-latitude", "moment"], function (_exports, _model, _isValidCoordinates, _extractLongitude, _extractLatitude, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PortModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.hasMany)('terminal'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('point'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('raw'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('date'), _dec11 = (0, _model.attr)('date'), _dec12 = Ember.computed('location'), _dec13 = Ember.computed('location'), _dec14 = Ember.computed('latitude', 'longitude'), _dec15 = Ember.computed('latitude', 'longitude'), _dec16 = Ember.computed('latitude', 'longitude'), _dec17 = Ember.computed('coordinates'), _dec18 = Ember.computed.not('hasValidCoordinates'), _dec19 = Ember.computed('country', 'country_data.name'), (_class = class PortModel extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "uuid", _descriptor, this);

      _initializerDefineProperty(this, "company_uuid", _descriptor2, this);

      _initializerDefineProperty(this, "terminals", _descriptor3, this);

      _initializerDefineProperty(this, "name", _descriptor4, this);

      _initializerDefineProperty(this, "portcode", _descriptor5, this);

      _initializerDefineProperty(this, "location", _descriptor6, this);

      _initializerDefineProperty(this, "country", _descriptor7, this);

      _initializerDefineProperty(this, "country_data", _descriptor8, this);

      _initializerDefineProperty(this, "slug", _descriptor9, this);

      _initializerDefineProperty(this, "created_at", _descriptor10, this);

      _initializerDefineProperty(this, "updated_at", _descriptor11, this);

      _initializerDefineProperty(this, "hasInvalidCoordinates", _descriptor12, this);
    }

    /** @methods */
    toJson() {
      return this.serialize();
    }
    /** @computed */


    get latitude() {
      return (0, _extractLatitude.default)(this.location);
    }

    get longitude() {
      return (0, _extractLongitude.default)(this.location);
    }

    get coordinates() {
      // eslint-disable-next-line ember/no-get
      return [Ember.get(this, 'latitude'), Ember.get(this, 'longitude')];
    }

    get latlng() {
      return {
        // eslint-disable-next-line ember/no-get
        lat: Ember.get(this, 'latitude'),
        // eslint-disable-next-line ember/no-get
        lng: Ember.get(this, 'longitude')
      };
    }

    get latitudelongitude() {
      return {
        // eslint-disable-next-line ember/no-get
        latitude: Ember.get(this, 'latitude'),
        // eslint-disable-next-line ember/no-get
        longitude: Ember.get(this, 'longitude')
      };
    }

    get hasValidCoordinates() {
      return (0, _isValidCoordinates.default)(this.coordinates);
    }

    get countryName() {
      return Ember.get(this, 'country_data.name', this.country);
    }

    get createdAt() {
      return (0, _moment.default)(this.created_at).format('DD MMM YYYY');
    }

    get updatedAt() {
      return (0, _moment.default)(this.updated_at).format('DD MMM YYYY, HH:mm');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "company_uuid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "terminals", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "portcode", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "country_data", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "latitude", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "latitude"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "longitude", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "longitude"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "coordinates", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "coordinates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "latlng", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "latlng"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "latitudelongitude", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "latitudelongitude"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasValidCoordinates", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "hasValidCoordinates"), _class.prototype), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "hasInvalidCoordinates", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "countryName", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "countryName"), _class.prototype)), _class));
  _exports.default = PortModel;
});