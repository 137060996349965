define("@fleetbase/console/models/api-request-log", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApiRequestLog = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('number'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('raw'), _dec17 = (0, _model.attr)('raw'), _dec18 = (0, _model.attr)('raw'), _dec19 = (0, _model.attr)('raw'), _dec20 = (0, _model.attr)('raw'), _dec21 = (0, _model.attr)('raw'), _dec22 = (0, _model.attr)('raw'), _dec23 = (0, _model.attr)('raw'), _dec24 = (0, _model.attr)('raw'), _dec25 = (0, _model.attr)('date'), _dec26 = (0, _model.attr)('date'), _dec27 = (0, _model.attr)('date'), _dec28 = Ember.computed('status_code', 'reason_phrase'), _dec29 = Ember.computed('method', 'path'), _dec30 = Ember.computed('updated_at'), _dec31 = Ember.computed('updated_at'), _dec32 = Ember.computed('created_at'), _dec33 = Ember.computed('created_at'), (_class = class ApiRequestLog extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "uuid", _descriptor, this);

      _initializerDefineProperty(this, "company_uuid", _descriptor2, this);

      _initializerDefineProperty(this, "api_credential_uuid", _descriptor3, this);

      _initializerDefineProperty(this, "public_id", _descriptor4, this);

      _initializerDefineProperty(this, "api_credential_name", _descriptor5, this);

      _initializerDefineProperty(this, "method", _descriptor6, this);

      _initializerDefineProperty(this, "path", _descriptor7, this);

      _initializerDefineProperty(this, "full_url", _descriptor8, this);

      _initializerDefineProperty(this, "status_code", _descriptor9, this);

      _initializerDefineProperty(this, "reason_phrase", _descriptor10, this);

      _initializerDefineProperty(this, "duration", _descriptor11, this);

      _initializerDefineProperty(this, "ip_address", _descriptor12, this);

      _initializerDefineProperty(this, "version", _descriptor13, this);

      _initializerDefineProperty(this, "source", _descriptor14, this);

      _initializerDefineProperty(this, "content_type", _descriptor15, this);

      _initializerDefineProperty(this, "related_resources", _descriptor16, this);

      _initializerDefineProperty(this, "related", _descriptor17, this);

      _initializerDefineProperty(this, "query_params", _descriptor18, this);

      _initializerDefineProperty(this, "request_headers", _descriptor19, this);

      _initializerDefineProperty(this, "request_body", _descriptor20, this);

      _initializerDefineProperty(this, "request_raw_body", _descriptor21, this);

      _initializerDefineProperty(this, "response_headers", _descriptor22, this);

      _initializerDefineProperty(this, "response_body", _descriptor23, this);

      _initializerDefineProperty(this, "response_raw_body", _descriptor24, this);

      _initializerDefineProperty(this, "deleted_at", _descriptor25, this);

      _initializerDefineProperty(this, "created_at", _descriptor26, this);

      _initializerDefineProperty(this, "updated_at", _descriptor27, this);
    }

    get status() {
      return `${this.status_code} ${this.reason_phrase}`;
    }

    get description() {
      return `${this.method} /${this.path}`;
    }

    get updatedAgo() {
      return (0, _moment.default)(this.updated_at).fromNow();
    }

    get updatedAt() {
      return (0, _moment.default)(this.updated_at).format('DD/MM/YY, HH:mm:ss A');
    }

    get createdAgo() {
      return (0, _moment.default)(this.created_at).fromNow();
    }

    get createdAt() {
      return (0, _moment.default)(this.created_at).format('DD/MM/YY, HH:mm:ss A');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "company_uuid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "api_credential_uuid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "public_id", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "api_credential_name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "method", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "path", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "full_url", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "status_code", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "reason_phrase", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "duration", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "ip_address", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "source", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "content_type", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "related_resources", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "related", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "query_params", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "request_headers", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "request_body", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "request_raw_body", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "response_headers", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "response_body", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "response_raw_body", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "status", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "status"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "description", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype)), _class));
  _exports.default = ApiRequestLog;
});