define("@fleetbase/console/utils/format-currency", ["exports", "@fleetbase/console/utils/get-currency", "accounting/format-money"], function (_exports, _getCurrency, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatCurrency;

  function formatCurrency() {
    let amount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    let currencyCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'USD';
    let currency = (0, _getCurrency.default)(currencyCode);
    return (0, _formatMoney.default)(!currency.decimalSeparator ? amount : amount / 100, currency.symbol, currency.precision, currency.thousandSeparator, currency.decimalSeparator);
  }
});