define("@fleetbase/console/templates/onboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fBVhM1AF",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-scrollable\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 dark:bg-gray-900 sm:px-6 lg:px-8\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"w-full max-w-md\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/templates/onboard.hbs"
    }
  });

  _exports.default = _default;
});