define("@fleetbase/console/modifiers/did-resize", ["exports", "ember-did-resize-modifier/modifiers/did-resize"], function (_exports, _didResize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _didResize.default;
    }
  });
});