define("@fleetbase/console/components/render-out", ["exports", "greenfreight-extension/components/render-out"], function (_exports, _renderOut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _renderOut.default;
    }
  });
});