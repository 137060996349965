define("@fleetbase/console/helpers/stripe-price-additional", ["exports", "@fleetbase/console/utils/format-currency"], function (_exports, _formatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function stripePriceAdditional(_ref) {
    let [price, unit = 'order'] = _ref;
    const unitAmount = price.tiers[1].unit_amount;
    const priceCurrency = price.currency;
    const formattedPrice = (0, _formatCurrency.default)(unitAmount, priceCurrency);
    return `${formattedPrice} per additional ${unit}`;
  });

  _exports.default = _default;
});