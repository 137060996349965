define("@fleetbase/console/templates/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "npWR1CQm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 dark:bg-gray-900 sm:px-6 lg:px-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"w-full max-w-md\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/templates/auth.hbs"
    }
  });

  _exports.default = _default;
});