define("@fleetbase/console/system/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const services = [{
    title: 'FleetOps',
    icon: '',
    description: 'Feature complete last-mile delivery system.',
    route: 'fleet-ops',
    active: true
  }, {
    title: 'Developers Console',
    icon: '',
    description: 'Feature complete developer resource system.',
    route: 'developers-console',
    active: true
  }, {
    title: 'Identity and Access Management',
    icon: '',
    description: 'Feature complete identity and access management.',
    route: 'iam',
    active: true
  }, {
    title: 'HyperBite',
    description: 'Feature complete food delviery and restaurant management system.',
    route: 'hyper-bite',
    active: false
  }, {
    title: 'OxWorks',
    description: 'Feature complete haulage system.',
    route: 'ox-works',
    active: false
  }, {
    title: 'Ledger',
    description: 'Feature complete accounting system.',
    route: 'ledger',
    active: false
  }];
  var _default = services;
  _exports.default = _default;
});