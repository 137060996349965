define("@fleetbase/console/utils/leaflet-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = leafletIcon;

  /* global L */

  /**
   * Represents an icon to provide when creating a marker.
   * More information about its possible options [here](https://leafletjs.com/reference-1.7.1.html#icon-option).
   *
   * @function leafletIcon
   * @param {Object} options the Icon options object
   * @return {Icon}
   */
  function leafletIcon() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return L.icon(options);
  }
});