define("@fleetbase/console/models/permission", ["exports", "@ember-data/model", "moment", "ember-inflector", "@fleetbase/console/utils/get-permission-action", "@fleetbase/console/utils/get-permission-resource", "@fleetbase/console/utils/get-service-name", "@fleetbase/console/utils/words"], function (_exports, _model, _moment, _emberInflector, _getPermissionAction, _getPermissionResource, _getServiceName, _words) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PermissionModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.attr)('date'), _dec5 = Ember.computed('name'), _dec6 = Ember.computed('name'), _dec7 = Ember.computed('name'), _dec8 = Ember.computed('actionName', 'name', 'resourceName', 'serviceName'), _dec9 = Ember.computed('updated_at'), _dec10 = Ember.computed('updated_at'), _dec11 = Ember.computed('created_at'), _dec12 = Ember.computed('created_at'), (_class = class PermissionModel extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "guard_name", _descriptor2, this);

      _initializerDefineProperty(this, "created_at", _descriptor3, this);

      _initializerDefineProperty(this, "updated_at", _descriptor4, this);
    }

    /** @methods */
    toJSON() {
      return {
        name: this.name,
        guard_name: this.guard_name,
        created_at: this.created_at,
        updated_at: this.updated_at
      };
    }
    /** @computed */


    get serviceName() {
      return (0, _getServiceName.default)(this.name);
    }

    get actionName() {
      return (0, _getPermissionAction.default)(this.name);
    }

    get resourceName() {
      return (0, _getPermissionResource.default)(this.name);
    }

    get description() {
      return `Enables permission to ${(0, _words.default)(this.actionName.toLowerCase())} ${(0, _emberInflector.pluralize)(this.resourceName)} from ${this.serviceName}`;
    }

    get updatedAgo() {
      return (0, _moment.default)(this.updated_at).fromNow();
    }

    get updatedAt() {
      return (0, _moment.default)(this.updated_at).format('DD MMM YYYY');
    }

    get createdAgo() {
      return (0, _moment.default)(this.created_at).fromNow();
    }

    get createdAt() {
      return (0, _moment.default)(this.created_at).format('DD MMM YYYY');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "guard_name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "serviceName", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "serviceName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "actionName", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "actionName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resourceName", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "resourceName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "description", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype)), _class));
  _exports.default = PermissionModel;
});