define("@fleetbase/console/utils/to-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ToModel extends Ember.CoreObject {
    fn(record, modelName) {
      const owner = Ember.getOwner(this);
      const store = owner.lookup('service:store');
      const normalized = store.normalize(modelName, record);
      return store.push(normalized);
    }

  }

  const toModel = (record, modelName) => {
    return ToModel.create().fn(record, modelName);
  };

  var _default = toModel;
  _exports.default = _default;
});