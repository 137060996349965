define("@fleetbase/console/templates/console/account/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Itr/68mm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container mx-auto\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content-panel my-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"content-panel-header items-center\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n                \"],[10,\"h3\"],[14,0,\"mr-4 text-xl font-bold dark:text-gray-100\"],[12],[2,\"\\n                    Notification Settings\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"content-panel-body p-6\"],[12],[2,\"\\n            \"],[10,\"form\"],[14,0,\"flex mb-6\"],[12],[2,\"\\n                \\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fleetbase/console/templates/console/account/notifications.hbs"
    }
  });

  _exports.default = _default;
});