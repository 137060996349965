define("@fleetbase/console/components/queued-file", ["exports", "storefront/components/queued-file"], function (_exports, _queuedFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _queuedFile.default;
    }
  });
});