define("@fleetbase/console/components/ebmm-modals-container/custom-modal", ["exports", "ember-bootstrap-modals-manager/components/ebmm-modals-container/custom-modal"], function (_exports, _customModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _customModal.default;
    }
  });
});