define("@fleetbase/console/utils/path-to-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pathToRoute;

  function pathToRoute() {
    let path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    if (!path.startsWith('console')) {
      path = `console.${path}`;
    }

    path = path.replace(/\//gi, '.');
    path = path.replace('.ops.', '.operations.');
    return path;
  }
});