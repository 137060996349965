define("@fleetbase/console/utils/auto-serialize", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = autoSerialize;

  const serialize = model => {
    if (typeof (model === null || model === void 0 ? void 0 : model.toJSON) === 'function') {
      return model.toJSON();
    }

    if (typeof (model === null || model === void 0 ? void 0 : model.toJson) === 'function') {
      return model.toJson();
    }

    if (typeof (model === null || model === void 0 ? void 0 : model.serialize) === 'function') {
      return model.serialize();
    }

    return autoSerialize(model);
  };

  function autoSerialize(model) {
    let except = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    if (Ember.isArray(model)) {
      return model.map(record => autoSerialize(record, except));
    }

    if (!(model instanceof _model.default)) {
      return {};
    }

    const serialized = {}; // set uuid from id

    serialized.id = model.id;
    serialized.uuid = model.id; // serialize attributes

    model.eachAttribute(attr => {
      if (except.includes(attr)) {
        return;
      }

      serialized[attr] = model[attr];
    }); // serialize relationships

    model.eachRelationship((attr, descriptor) => {
      const {
        kind
      } = descriptor;

      if (except.includes(attr)) {
        return;
      }

      if (kind === 'hasMany') {
        serialized[attr] = model[attr].toArray().map(instance => serialize(instance));
      } else {
        serialized[attr] = serialize(model[attr]);
      }
    });
    return serialized;
  }
});