define("@fleetbase/console/controllers/invite/for-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InviteForUserController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class InviteForUserController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor4, this);

      _initializerDefineProperty(this, "code", _descriptor5, this);

      _initializerDefineProperty(this, "isLoading", _descriptor6, this);
    }

    acceptInvite() {
      const {
        code
      } = this;
      this.isLoading = true;
      this.fetch.post('users/accept-company-invite', {
        code
      }).then(response => {
        this.session.manuallyAuthenticate(response.token);
        this.notifications.success('Invitation accepted, welcome to Fleetbase!');
        this.isLoading = false;
        return this.transitionToRoute('console').then(() => {
          if (response.needs_password && response.needs_password === true) {
            this.setPassword();
          }
        });
      }).catch(error => {
        this.notifications.serverError(error);
        this.isLoading = false;
      });
    }

    setPassword() {
      this.modalsManager.show('modals/set-password', {
        title: 'Set a new password',
        closeButton: false,
        backdropClose: false,
        hideDeclineButton: true,
        declineButtonDisabled: true,
        password: null,
        password_confirmation: null,
        confirm: modal => {
          modal.startLoading();
          const input = modal.getOptions(['password', 'password_confirmation']);
          return this.fetch.post('users/set-password', input);
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "code", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "acceptInvite", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "acceptInvite"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPassword", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setPassword"), _class.prototype)), _class));
  _exports.default = InviteForUserController;
});