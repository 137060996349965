define("@fleetbase/console/utils/get-currency", ["exports", "@fleetbase/ui/utils/get-currency"], function (_exports, _getCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _getCurrency.default;
    }
  });
  Object.defineProperty(_exports, "getCurrency", {
    enumerable: true,
    get: function () {
      return _getCurrency.getCurrency;
    }
  });
});