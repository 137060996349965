define("@fleetbase/console/components/basic-dropdown-hover", ["exports", "@fleetbase/ui/components/basic-dropdown-hover"], function (_exports, _basicDropdownHover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _basicDropdownHover.default;
    }
  });
});