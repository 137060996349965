define("@fleetbase/console/system/plugins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const plugins = [{
    title: 'Activity Notifications',
    description: 'Provides activity notifications which can be configured to both Customer or Faciliator or custom contact via multiple channels (SMS, Email, Whatsapp. etc..).',
    service: 'fleet-ops'
  }, {
    title: 'Vendor Compliance',
    description: 'Allow user to perform a compliance lookups on a vendor.',
    service: '__global',
    extension: {
      modals: [{
        title: 'Vendor Compliance Lookup',
        component: null,
        placement: ['index.action-menu', 'view.action-menu'],
        description: 'Allow user to perform a compliance lookup on a vendor.',
        service: 'fleet-ops'
      }],
      tooltips: [{
        title: 'Compliance Info',
        description: 'Provides contextual information about a vendors compliance.',
        url: 'https://api.notorum.com/lookup/{id}',
        target: '[data-compliance-id]',
        content: '{response.content}'
      }]
    }
  }];
  var _default = plugins;
  _exports.default = _default;
});