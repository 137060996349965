define("@fleetbase/console/modifiers/set-max-width", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function setMaxWidth(element, _ref) {
    let [to = '100%'] = _ref;
    // defaults to 100% - if numeric 100px/100em then use literal value, otherwise assume element selector to use it's maxWidth if found
    const maxWidth = to.match(/^\d/) ? `${to}` : document.querySelector(to);

    if (maxWidth) {
      // sets this elements maxwidth to either a specific max width of the maxwidth of another element
      element.style.maxWidth = typeof maxWidth === 'string' ? maxWidth : maxWidth.offsetWidth;
    }
  });

  _exports.default = _default;
});