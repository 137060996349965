define("@fleetbase/console/routes/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AuthRoute extends Ember.Route {}

  _exports.default = AuthRoute;
});