define("@fleetbase/console/utils/apply-column-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = applyColumnFilters;

  function applyColumnFilters() {
    return true;
  }
});