define("@fleetbase/console/templates/settings", ["exports", "storefront/templates/settings"], function (_exports, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _settings.default;
    }
  });
});