define("@fleetbase/console/modifiers/background-url", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-empty-pattern */
  var _default = (0, _emberModifier.modifier)(function backgroundUrl(element, _ref) {
    let [url] = _ref;
    let modifierOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const options = {
      overlay: false,
      gradient: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))',
      size: 'cover',
      ...modifierOptions
    }; //{ url, overlay: false, gradient: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))', size: 'cover' }

    element.style.background = options.overlay ? `${options.gradient}, url('${url}')` : `url('${url}')`;
    element.style.backgroundSize = options.size;
  });

  _exports.default = _default;
});