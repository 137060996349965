define("@fleetbase/console/helpers/format-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const secondsToTime = secs => {
    const hours = Math.floor(secs / (60 * 60));
    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);
    const obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };

  var _default = Ember.Helper.helper(function formatDuration(_ref) {
    let [secs] = _ref;
    let time = secondsToTime(secs);
    let parts = [];

    if (time.h) {
      parts.push(`${time.h}h`);
    }

    if (time.m) {
      parts.push(`${time.m}m`);
    }

    if (parts.length < 2 && time.s) {
      parts.push(`${time.s}s`);
    }

    if (parts.length === 0) {
      parts.push('0s');
    }

    return parts.join(' ');
  });

  _exports.default = _default;
});