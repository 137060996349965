define("@fleetbase/console/templates/console/extensions/installed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "54vBFEc4",
    "block": "{\"symbols\":[\"extension\",\"index\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"container mx-auto\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"max-w-6xl my-10 mx-auto\"],[12],[2,\"\\n        \"],[10,\"section\"],[14,\"aria-labelledby\",\"extensions-browser\"],[14,0,\"space-y-6 lg:col-start-1 lg:col-span-2\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[8,\"extension-block\",[],[[\"@extension\",\"@onUninstalled\"],[[32,1],[30,[36,0],[[32,0,[\"removeExtension\"]],[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[2,\"                    \"],[10,\"div\"],[14,0,\"col-span-2\"],[12],[2,\"\\n                        \"],[10,\"div\"],[14,0,\"flex flex-col items-center justify-center p-6\"],[12],[2,\"\\n                            \"],[10,\"div\"],[14,0,\"my-6 opacity-75\"],[12],[2,\"\\n                                \"],[8,\"fa-icon\",[[24,0,\"text-blue-400\"]],[[\"@icon\",\"@size\",\"@pluse\"],[\"shapes\",\"4x\",true]],null],[2,\"\\n                            \"],[13],[2,\"\\n                            \"],[10,\"h3\"],[14,0,\"dark:text-gray-100 font-semibold\"],[12],[2,\"No extensions installed\"],[13],[2,\"\\n                        \"],[13],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/templates/console/extensions/installed.hbs"
    }
  });

  _exports.default = _default;
});