define("@fleetbase/console/templates/operations/orders/index/config/types", ["exports", "fleet-ops/templates/operations/orders/index/config/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _types.default;
    }
  });
});