define("@fleetbase/console/services/notifications", ["exports", "ember-cli-notifications/services/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NotificationsService extends _notifications.default {
    /**
     * Handles errors from the server
     *
     * @param {Error} error
     * @void
     */
    serverError(error) {
      let fallbackMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Oops! Something went wrong with your request.';
      let options = arguments.length > 2 ? arguments[2] : undefined;

      if (Ember.isArray(error.errors)) {
        const errorMessage = error.errors.firstObject;
        return this.error(errorMessage || fallbackMessage, options);
      }

      if (error instanceof Error) {
        return this.error(error.message || fallbackMessage, options);
      }

      return this.error(error || fallbackMessage, options);
    }

    invoke(type, message) {
      if (typeof message === 'function') {
        for (var _len = arguments.length, params = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
          params[_key - 2] = arguments[_key];
        }

        this[type](message(...params));
      } else {
        this[type](message);
      }
    }

  }

  _exports.default = NotificationsService;
});