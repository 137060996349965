define("@fleetbase/console/components/leaflet-rotated-marker", ["exports", "ember-leaflet/components/marker-layer", "leaflet-rotatedmarker"], function (_exports, _markerLayer, _leafletRotatedmarker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class LeafletRotatedMarkerComponent extends _markerLayer.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "leafletOptions", [...this.leafletOptions,
      /**
       * Rotation angle, in degrees, clockwise.
       *
       * @argument rotationAngle
       * @type {Number}
       */
      'rotationAngle',
      /**
       * The rotation center, as a transform-origin CSS rule.
       *
       * @argument rotationOrigin
       * @type {String}
       */
      'rotationOrigin']);
    }

  }

  _exports.default = LeafletRotatedMarkerComponent;
});