define("@fleetbase/console/components/widget/recommended-todo", ["exports", "fleet-ops/components/widget/recommended-todo"], function (_exports, _recommendedTodo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _recommendedTodo.default;
    }
  });
});