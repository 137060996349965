define("@fleetbase/console/helpers/get-int", ["exports", "greenfreight-extension/helpers/get-int"], function (_exports, _getInt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _getInt.default;
    }
  });
  Object.defineProperty(_exports, "getInt", {
    enumerable: true,
    get: function () {
      return _getInt.getInt;
    }
  });
});