define("@fleetbase/console/components/ui/money-input", ["exports", "@fleetbase/ui/components/ui/money-input"], function (_exports, _moneyInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _moneyInput.default;
    }
  });
});