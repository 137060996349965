define("@fleetbase/console/helpers/strip-character", ["exports", "@fleetbase/ui/helpers/strip-character"], function (_exports, _stripCharacter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stripCharacter.default;
    }
  });
  Object.defineProperty(_exports, "stripCharacter", {
    enumerable: true,
    get: function () {
      return _stripCharacter.stripCharacter;
    }
  });
});