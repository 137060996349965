define("@fleetbase/console/utils/is-numeric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isNumeric;

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
});