define("@fleetbase/console/components/page-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
      <div class="px-8 pt-8 pb-1 section-header">
          <div class="flex items-center">
              <Ui::Spinner @iconClass="text-sky-500 fa-spin-800ms mr-3" />
              <span class="font-semibold text-gray-100 test-sm">
                  {{or @loadingMessage "Loading..."}}
              </span>
          </div>
      </div>
  </div>
  */
  {
    "id": "BX3Ni8Iz",
    "block": "{\"symbols\":[\"&attrs\",\"@loadingMessage\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"px-8 pt-8 pb-1 section-header\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n            \"],[8,\"ui/spinner\",[],[[\"@iconClass\"],[\"text-sky-500 fa-spin-800ms mr-3\"]],null],[2,\"\\n            \"],[10,\"span\"],[14,0,\"font-semibold text-gray-100 test-sm\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[[32,2],\"Loading...\"],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"or\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/page-loader.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});