define("@fleetbase/console/utils/replace-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = replaceTableRow;

  function replaceTableRow(table, newRow, findBy) {
    const rows = table.rows.content;
    let rowIndex = false;

    if (typeof findBy === 'string') {
      rowIndex = rows.findIndex(row => Ember.get(row, findBy) === Ember.get(newRow, findBy));
    } else if (typeof findBy === 'function') {
      rowIndex = rows.findIndex(findBy);
    }

    if (rowIndex) {
      table.removeRowAt(rowIndex);
      table.insertRowAt(rowIndex, newRow);
      return true;
    }

    return false;
  }
});