define("@fleetbase/console/serializers/permission", ["exports", "@fleetbase/console/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PermissionSerializer extends _application.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "primaryKey", 'id');
    }

  }

  _exports.default = PermissionSerializer;
});