define("@fleetbase/console/components/ember-dragula-container", ["exports", "@zestia/ember-dragula/components/ember-dragula-container"], function (_exports, _emberDragulaContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emberDragulaContainer.default;
    }
  });
});