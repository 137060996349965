define("@fleetbase/console/routes/onboard/hello", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OnboardHelloRoute extends Ember.Route {}

  _exports.default = OnboardHelloRoute;
});