define("@fleetbase/console/components/phone-input", ["exports", "@glimmer/component", "intl-tel-input"], function (_exports, _component, _intlTelInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Input type="tel" @value={{@value}} {{did-insert (fn this.setupIntlTelInput)}} {{on "keyup" (fn this.onInput)}} ...attributes />
  */
  {
    "id": "zZ5tqFJx",
    "block": "{\"symbols\":[\"@value\",\"&attrs\"],\"statements\":[[8,\"input\",[[17,2],[24,4,\"tel\"],[4,[38,1],[[30,[36,0],[[32,0,[\"setupIntlTelInput\"]]],null]],null],[4,[38,2],[\"keyup\",[30,[36,0],[[32,0,[\"onInput\"]]],null]],null]],[[\"@value\"],[[32,1]]],null]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\",\"on\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/phone-input.hbs"
    }
  });

  let PhoneInputComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class PhoneInputComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "iti", _descriptor2, this);
    }

    setupIntlTelInput(element) {
      this.iti = (0, _intlTelInput.default)(element, {
        customContainer: `w-full ${this.args.wrapperClass || ''}`,
        initialCountry: 'auto',
        geoIpLookup: success => {
          this.fetch.get('actions/whois').then(response => {
            success(response.country_code);
          });
        },
        utilsScript: '/assets/libphonenumber/utils.js'
      });
    }

    onInput() {
      const number = this.iti.getNumber();

      if (typeof this.args.onInput === 'function') {
        this.args.onInput(number);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "iti", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupIntlTelInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setupIntlTelInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInput", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype)), _class));
  _exports.default = PhoneInputComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PhoneInputComponent);
});