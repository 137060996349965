define("@fleetbase/console/services/current-user", ["exports", "ember-local-storage", "logrocket", "moment"], function (_exports, _emberLocalStorage, _logrocket, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurrentUserService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = (0, _emberLocalStorage.storageFor)('user-options'), _dec7 = Ember.computed.alias('user.id'), _dec8 = Ember.computed.alias('user.name'), _dec9 = Ember.computed.alias('user.phone'), _dec10 = Ember.computed.alias('user.email'), _dec11 = Ember.computed.alias('user.company_uuid'), _dec12 = Ember.computed('id'), (_class = class CurrentUserService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "fetch", _descriptor3, this);

      _initializerDefineProperty(this, "theme", _descriptor4, this);

      _initializerDefineProperty(this, "notifications", _descriptor5, this);

      _initializerDefineProperty(this, "options", _descriptor6, this);

      _initializerDefineProperty(this, "id", _descriptor7, this);

      _initializerDefineProperty(this, "name", _descriptor8, this);

      _initializerDefineProperty(this, "phone", _descriptor9, this);

      _initializerDefineProperty(this, "email", _descriptor10, this);

      _initializerDefineProperty(this, "companyId", _descriptor11, this);
    }

    /**
     * Loads the current authenticated user
     *
     * @void
     */
    async load() {
      if (this.session.isAuthenticated) {
        let user = await this.store.queryRecord('user', {
          me: true
        });
        this.set('user', user);
      }
    }
    /**
     * Resolves a user model.
     *
     * @return {Promise}
     */


    promiseUser() {
      const NoUserAuthenticatedError = new Error('Failed to authenticate user.');
      return new Promise((resolve, reject) => {
        if (this.session.isAuthenticated) {
          return this.store.queryRecord('user', {
            me: true
          }).then(user => {
            this.set('user', user);
            this.theme.setEnvironment(); // set environment from user option
            // identify to logrocket

            _logrocket.default.identify(user.id, {
              name: user.name,
              email: user.email,
              phone: user.phone,
              company: user.company_name,
              ip_address: user.ip_address,
              type: user.type
            });

            resolve(user);
          }).catch(() => {
            reject(NoUserAuthenticatedError);
          });
        } // reject(NoUserAuthenticatedError);

      });
    }
    /**
     * The prefix for this user options
     *
     * @var {String}
     */


    get optionsPrefix() {
      return `${this.id}:`;
    }

    get latitude() {
      const whois = this.getOption('whois');
      return whois === null || whois === void 0 ? void 0 : whois.latitude;
    }

    get longitude() {
      const whois = this.getOption('whois');
      return whois === null || whois === void 0 ? void 0 : whois.longitude;
    }
    /**
     * Sets a user's option in local storage
     *
     * @param {String} key
     * @param {Mixed} value
     * @return {CurrentUserService}
     */


    setOption(key, value) {
      key = `${this.optionsPrefix}${Ember.String.dasherize(key)}`;
      this.options.set(key, value);
      return this;
    }
    /**
     * Retrieves a user option from local storage
     *
     * @param {String} key
     * @return {Mixed}
     */


    getOption(key) {
      key = `${this.optionsPrefix}${Ember.String.dasherize(key)}`;
      return this.options.get(key);
    }
    /**
     * Retrieves a user option from local storage
     *
     * @param {String} key
     * @return {Mixed}
     */


    getWhoisProperty(prop) {
      const whois = this.getOption('whois');

      if (!whois || typeof whois !== 'object') {
        return null;
      }

      return Ember.get(whois, prop);
    }
    /**
     * Checks if an option exists in users local storage
     *
     * @param {String} key
     * @return {Boolean}
     */


    hasOption(key) {
      return this.getOption(key) !== undefined;
    }
    /**
     * Track the user's visit to a service.
     *
     *
     */


    trackUserVisit(route) {
      let meta = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const visits = this.getOption('visits') || {};
      visits[route] = {
        visited: new Date().toString(),
        ...meta
      };
      this.setOption('visits', visits);
    }
    /**
     * Track the user's visit to a service.
     *
     * @
     */


    getFrequentlyVisited() {
      const visits = this.getOption('visits') || {};
      const routes = Object.keys(visits);
      const frequent = [];
      const expiryDate = (0, _moment.default)().add(7, 'days');

      for (let i = 0; i < routes.length; i++) {
        let visitInfo = visits[routes[i]];
        let lastVisit = (0, _moment.default)(new Date(visitInfo.visited));

        if (lastVisit.isBefore(expiryDate)) {
          frequent.pushObject({
            route: routes[i],
            visitInfo
          });
        }
      }

      return frequent;
    }
    /**
     * Check current session for standard subscription.
     */


    checkExtensionAccess(extension, transition) {
      return new Promise((resolve, reject) => {
        return this.fetch.post('auth/check-access', {
          extension
        }).then(response => {
          if (response && response.can_access && response.can_access === true) {
            return resolve(true);
          }

          transition.abort();
          reject(false);
        }).catch(error => {
          this.notifications.serverError(error);
          transition.abort();
          reject(error);
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "id", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "companyId", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "optionsPrefix", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "optionsPrefix"), _class.prototype)), _class));
  _exports.default = CurrentUserService;
});