define("@fleetbase/console/routes/console/extensions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ConsoleExtensionsRoute extends Ember.Route {
    async setupController(controller, model) {
      super.setupController(controller, model); // load all categories

      controller.categories = await this.store.query('category', {
        for: 'extension'
      });
      controller.types = await this.store.query('type', {
        for: 'extension'
      });
    }

  }

  _exports.default = ConsoleExtensionsRoute;
});