define("@fleetbase/console/models/service-rate", ["exports", "@ember-data/model", "@fleetbase/console/utils/is-model", "date-fns", "@fleetbase/console/utils/auto-serialize"], function (_exports, _model, _isModel, _dateFns, _autoSerialize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ServiceRate = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.hasMany)('service-rate-fee'), _dec6 = (0, _model.hasMany)('service-rate-parcel-fee'), _dec7 = (0, _model.belongsTo)('service-area'), _dec8 = (0, _model.belongsTo)('zone'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('boolean'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('boolean'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('string'), _dec28 = (0, _model.attr)('string'), _dec29 = (0, _model.attr)('string'), _dec30 = (0, _model.attr)('string'), _dec31 = (0, _model.attr)('date'), _dec32 = (0, _model.attr)('date'), _dec33 = (0, _model.attr)('date'), _dec34 = Ember.computed('updated_at'), _dec35 = Ember.computed('updated_at'), _dec36 = Ember.computed('created_at'), _dec37 = Ember.computed('created_at'), _dec38 = Ember.computed('rate_calculation_method'), _dec39 = Ember.computed('rate_calculation_method'), _dec40 = Ember.computed('rate_calculation_method'), _dec41 = Ember.computed('service_type'), _dec42 = Ember.computed('peak_hours_calculation_method'), _dec43 = Ember.computed('peak_hours_calculation_method'), _dec44 = Ember.computed('cod_calculation_method'), _dec45 = Ember.computed('cod_calculation_method'), (_class = class ServiceRate extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "public_id", _descriptor, this);

      _initializerDefineProperty(this, "company_uuid", _descriptor2, this);

      _initializerDefineProperty(this, "service_area_uuid", _descriptor3, this);

      _initializerDefineProperty(this, "zone_uuid", _descriptor4, this);

      _initializerDefineProperty(this, "rate_fees", _descriptor5, this);

      _initializerDefineProperty(this, "parcel_fees", _descriptor6, this);

      _initializerDefineProperty(this, "service_area", _descriptor7, this);

      _initializerDefineProperty(this, "zone", _descriptor8, this);

      _initializerDefineProperty(this, "service_area_name", _descriptor9, this);

      _initializerDefineProperty(this, "zone_name", _descriptor10, this);

      _initializerDefineProperty(this, "service_name", _descriptor11, this);

      _initializerDefineProperty(this, "service_type", _descriptor12, this);

      _initializerDefineProperty(this, "base_fee", _descriptor13, this);

      _initializerDefineProperty(this, "per_meter_flat_rate_fee", _descriptor14, this);

      _initializerDefineProperty(this, "per_meter_unit", _descriptor15, this);

      _initializerDefineProperty(this, "algorithm", _descriptor16, this);

      _initializerDefineProperty(this, "rate_calculation_method", _descriptor17, this);

      _initializerDefineProperty(this, "has_cod_fee", _descriptor18, this);

      _initializerDefineProperty(this, "cod_calculation_method", _descriptor19, this);

      _initializerDefineProperty(this, "cod_flat_fee", _descriptor20, this);

      _initializerDefineProperty(this, "cod_percent", _descriptor21, this);

      _initializerDefineProperty(this, "has_peak_hours_fee", _descriptor22, this);

      _initializerDefineProperty(this, "peak_hours_calculation_method", _descriptor23, this);

      _initializerDefineProperty(this, "peak_hours_flat_fee", _descriptor24, this);

      _initializerDefineProperty(this, "peak_hours_percent", _descriptor25, this);

      _initializerDefineProperty(this, "peak_hours_start", _descriptor26, this);

      _initializerDefineProperty(this, "peak_hours_end", _descriptor27, this);

      _initializerDefineProperty(this, "currency", _descriptor28, this);

      _initializerDefineProperty(this, "duration_terms", _descriptor29, this);

      _initializerDefineProperty(this, "estimated_days", _descriptor30, this);

      _initializerDefineProperty(this, "deleted_at", _descriptor31, this);

      _initializerDefineProperty(this, "created_at", _descriptor32, this);

      _initializerDefineProperty(this, "updated_at", _descriptor33, this);
    }

    get updatedAgo() {
      return (0, _dateFns.formatDistanceToNow)(this.updated_at);
    }

    get updatedAt() {
      return (0, _dateFns.format)(this.updated_at, 'PPP');
    }

    get createdAgo() {
      return (0, _dateFns.formatDistanceToNow)(this.created_at);
    }

    get createdAt() {
      if (!this.created_at) {
        return null;
      }

      return (0, _dateFns.format)(this.created_at, 'PPP p');
    }

    get isFixedMeter() {
      return this.rate_calculation_method === 'fixed_meter';
    }

    get isPerMeter() {
      return this.rate_calculation_method === 'per_meter';
    }

    get isAlgorithm() {
      return this.rate_calculation_method === 'algo';
    }

    get isParcelService() {
      return this.service_type === 'parcel';
    }

    get hasPeakHoursFlatFee() {
      return this.peak_hours_calculation_method === 'flat';
    }

    get hasPeakHoursPercentageFee() {
      return this.peak_hours_calculation_method === 'percentage';
    }

    get hasCodFlatFee() {
      return this.cod_calculation_method === 'flat';
    }

    get hasCodPercentageFee() {
      return this.cod_calculation_method === 'percentage';
    }

    toJSON() {
      return (0, _autoSerialize.default)(this, ['service_area', 'zone']);
    }

    setServiceRateFees() {
      let fees = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      if (!Ember.isArray(fees)) {
        return this;
      }

      let serviceRateFees = [];
      let owner = Ember.getOwner(this);
      let store = owner.lookup('service:store');

      for (let i = 0; i < fees.length; i++) {
        let rateFee = fees.objectAt(i);
        rateFee.currency = this.currency;

        if ((0, _isModel.default)(rateFee)) {
          serviceRateFees.pushObject(rateFee);
        } else {
          serviceRateFees.pushObject(store.createRecord('service-rate-fee', rateFee));
        }
      }

      this.rate_fees.pushObjects(serviceRateFees);
      return this;
    }

    setServiceRateParcelFees() {
      let fees = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      if (!Ember.isArray(fees)) {
        return this;
      }

      let serviceRateParcelFees = [];
      let owner = Ember.getOwner(this);
      let store = owner.lookup('service:store');

      for (let i = 0; i < fees.length; i++) {
        let parcelFee = fees.objectAt(i);
        parcelFee.currency = this.currency;

        if ((0, _isModel.default)(parcelFee)) {
          serviceRateParcelFees.pushObject(parcelFee);
        } else {
          serviceRateParcelFees.pushObject(store.createRecord('service-rate-parcel-fee', parcelFee));
        }
      }

      this.parcel_fees.pushObjects(serviceRateParcelFees);
      return this;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "public_id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "company_uuid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "service_area_uuid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "zone_uuid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rate_fees", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "parcel_fees", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "service_area", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "zone", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "service_area_name", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "zone_name", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "service_name", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "service_type", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "base_fee", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "per_meter_flat_rate_fee", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "per_meter_unit", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "algorithm", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "rate_calculation_method", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "has_cod_fee", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "cod_calculation_method", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "cod_flat_fee", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "cod_percent", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "has_peak_hours_fee", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "peak_hours_calculation_method", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "peak_hours_flat_fee", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "peak_hours_percent", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "peak_hours_start", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "peak_hours_end", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "duration_terms", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "estimated_days", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec35], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec36], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec37], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isFixedMeter", [_dec38], Object.getOwnPropertyDescriptor(_class.prototype, "isFixedMeter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPerMeter", [_dec39], Object.getOwnPropertyDescriptor(_class.prototype, "isPerMeter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isAlgorithm", [_dec40], Object.getOwnPropertyDescriptor(_class.prototype, "isAlgorithm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isParcelService", [_dec41], Object.getOwnPropertyDescriptor(_class.prototype, "isParcelService"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasPeakHoursFlatFee", [_dec42], Object.getOwnPropertyDescriptor(_class.prototype, "hasPeakHoursFlatFee"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasPeakHoursPercentageFee", [_dec43], Object.getOwnPropertyDescriptor(_class.prototype, "hasPeakHoursPercentageFee"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasCodFlatFee", [_dec44], Object.getOwnPropertyDescriptor(_class.prototype, "hasCodFlatFee"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasCodPercentageFee", [_dec45], Object.getOwnPropertyDescriptor(_class.prototype, "hasCodPercentageFee"), _class.prototype)), _class));
  _exports.default = ServiceRate;
});