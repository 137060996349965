define("@fleetbase/console/utils/is-letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isLetter;

  function isLetter(l) {
    return l.length === 1 && l.match(/[a-z]/i);
  }
});