define("@fleetbase/console/helpers/first-char", ["exports", "@fleetbase/ui/helpers/first-char"], function (_exports, _firstChar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _firstChar.default;
    }
  });
  Object.defineProperty(_exports, "firstChar", {
    enumerable: true,
    get: function () {
      return _firstChar.firstChar;
    }
  });
});