define("@fleetbase/console/utils/first", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = first;

  function first(arr) {
    let n = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

    if (!Ember.isArray(arr) || arr === null || arr.length === 0 || n > arr.length) {
      return null;
    }

    return n > 1 ? arr.slice(0, n) : arr[0];
  }
});