define("@fleetbase/console/helpers/flow-raw-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function flowRawStatus(_ref) {
    let [status] = _ref;

    if (!status || typeof status !== 'string') {
      return '';
    }

    if (status.includes('|')) {
      return status.slice(status.indexOf('|') + 1);
    }

    return status;
  });

  _exports.default = _default;
});