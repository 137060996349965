define("@fleetbase/console/components/modals/zone-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          <div class="input-group">
              <Ui::InputLabel @labelText="Zone Name" @helpText="Set a display name for the zone." @required={{true}} />
              <Input @value={{@options.zone.name}} type="text" class="w-full form-input" placeholder="Zone Name" />
          </div>
  
          <Ui::InputGroup @type="color" @value={{@options.zone.stroke_color}} @name="Zone Border Color" @helpText="Customize the border color for this zone." />
          <Ui::InputGroup @type="color" @value={{@options.zone.color}} @name="Zone Color" @helpText="Customize the fill color for this zone." />
  
          <div class="input-group">
              <Ui::InputLabel @labelText="Zone Name" @helpText="Optionally give the zone a description." />
              <Textarea @value={{@options.zone.description}} @rows={{3}} type="text" class="w-full form-input" placeholder="Description of Zone" />
          </div>
      </div>
  </Ui::Modal>
  */
  {
    "id": "N6NMjhUI",
    "block": "{\"symbols\":[\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,1],[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n            \"],[8,\"ui/input-label\",[],[[\"@labelText\",\"@helpText\",\"@required\"],[\"Zone Name\",\"Set a display name for the zone.\",true]],null],[2,\"\\n            \"],[8,\"input\",[[24,0,\"w-full form-input\"],[24,\"placeholder\",\"Zone Name\"],[24,4,\"text\"]],[[\"@value\"],[[32,2,[\"zone\",\"name\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[8,\"ui/input-group\",[],[[\"@type\",\"@value\",\"@name\",\"@helpText\"],[\"color\",[32,2,[\"zone\",\"stroke_color\"]],\"Zone Border Color\",\"Customize the border color for this zone.\"]],null],[2,\"\\n        \"],[8,\"ui/input-group\",[],[[\"@type\",\"@value\",\"@name\",\"@helpText\"],[\"color\",[32,2,[\"zone\",\"color\"]],\"Zone Color\",\"Customize the fill color for this zone.\"]],null],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n            \"],[8,\"ui/input-label\",[],[[\"@labelText\",\"@helpText\"],[\"Zone Name\",\"Optionally give the zone a description.\"]],null],[2,\"\\n            \"],[8,\"textarea\",[[24,0,\"w-full form-input\"],[24,\"placeholder\",\"Description of Zone\"],[24,4,\"text\"]],[[\"@value\",\"@rows\"],[[32,2,[\"zone\",\"description\"]],3]],null],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/zone-form.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});