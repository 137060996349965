define("@fleetbase/console/components/modals/install-prompt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container space-y-4">
          <div class="flex">
              <div>
                  <div class="flex items-center justify-center p-3 rounded-md bg-gray-600 shadow mr-4">
                      <FaIcon @icon="cloud-download" class="text-blue-400" @size="2x" />
                  </div>
              </div>
              <div class="flex-1 dark:text-gray-100">
                  <p class="text-base mb-2">You are attempting to install the extension "{{or @options.extensionName}}".
                      Are you sure you wish to proceed with this installation?</p>
                  <small>Some extensions require access to your resources.</small>
              </div>
          </div>
          {{#if @options.isInstalling}}
              <div>
                  <ProgressBar @task="install" @progress={{@options.installProgress}} />
              </div>
          {{/if}}
      </div>
  </Ui::Modal>
  */
  {
    "id": "yFrksA7D",
    "block": "{\"symbols\":[\"@options\",\"@modalIsOpened\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,2],[32,1],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container space-y-4\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n            \"],[10,\"div\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"flex items-center justify-center p-3 rounded-md bg-gray-600 shadow mr-4\"],[12],[2,\"\\n                    \"],[8,\"fa-icon\",[[24,0,\"text-blue-400\"]],[[\"@icon\",\"@size\"],[\"cloud-download\",\"2x\"]],null],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"flex-1 dark:text-gray-100\"],[12],[2,\"\\n                \"],[10,\"p\"],[14,0,\"text-base mb-2\"],[12],[2,\"You are attempting to install the extension \\\"\"],[1,[30,[36,0],[[32,1,[\"extensionName\"]]],null]],[2,\"\\\".\\n                    Are you sure you wish to proceed with this installation?\"],[13],[2,\"\\n                \"],[10,\"small\"],[12],[2,\"Some extensions require access to your resources.\"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"isInstalling\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[12],[2,\"\\n                \"],[8,\"progress-bar\",[],[[\"@task\",\"@progress\"],[\"install\",[32,1,[\"installProgress\"]]]],null],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"if\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/install-prompt.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});