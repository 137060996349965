define("@fleetbase/console/utils/last", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = last;

  const isNumber = value => typeof value === 'number' && isFinite(value);

  function last(arr) {
    let n = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

    if (!Ember.isArray(arr) || arr.length === 0) {
      return null;
    }

    n = isNumber(n) ? +n : 1;

    if (n === 1) {
      return arr[arr.length - 1];
    }

    let res = new Array(n);

    while (n--) {
      res[n] = arr[--len];
    }

    return res;
  }
});