define("@fleetbase/console/utils/get-pod-methods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getPodMethods;

  function getPodMethods() {
    return [{
      name: 'None',
      value: null
    }, {
      name: 'QR Code Scan',
      value: 'scan'
    }, {
      name: 'Signature',
      value: 'signature'
    }];
  }
});