define("@fleetbase/console/components/cell/ul-model-link-list", ["exports", "@fleetbase/ui/components/cell/ul-model-link-list"], function (_exports, _ulModelLinkList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ulModelLinkList.default;
    }
  });
});