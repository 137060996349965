define("@fleetbase/console/utils/is-longitude", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isLongitude;

  function isLongitude(coordinate) {
    return isFinite(coordinate) && Math.abs(coordinate) <= 180;
  }
});