define("@fleetbase/console/utils/extract-latitude", ["exports", "@fleetbase/console/utils/extract-coordinates", "terraformer"], function (_exports, _extractCoordinates, _terraformer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = extractLatitude;

  /* eslint-disable no-unused-vars */
  function extractLatitude(position) {
    let latitude, longitude;

    if (!position) {
      return 0;
    }

    if (position instanceof _terraformer.default.Point || Ember.isArray(position.coordinates)) {
      [latitude, longitude] = (0, _extractCoordinates.default)(position.coordinates);
      return latitude;
    }

    if (typeof position === 'object') {
      let latitude = position['lat'] || position['latitude'] || position['x'];
      return latitude;
    }

    [latitude, longitude] = (0, _extractCoordinates.default)(position);
    return latitude;
  }
});