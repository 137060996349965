define("@fleetbase/console/components/modals/driver-assign-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          <div class="grid grid-cols-1 gap-2 text-xs dark:text-gray-100">
              <Ui::InputGroup @name="Select Order" @value={{@options.selectedOrder}}>
                  <Ui::ModelSelect
                      @modelName="order"
                      @selectedModel={{@options.selectedOrder}}
                      @placeholder="Select Order to Assign"
                      @triggerClass="form-select form-input"
                      @infiniteScroll={{false}}
                      @renderInPlace={{true}}
                      @onChange={{fn (mut @options.selectedOrder)}} as |model|
                  >
                      <span class="uppercase">{{model.public_id}} - {{model.createdAt}}</span>
                  </Ui::ModelSelect>
              </Ui::InputGroup>
          </div>
      </div>
      {{yield}}
  </Ui::Modal>
  */
  {
    "id": "I9H1W+El",
    "block": "{\"symbols\":[\"model\",\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\",\"&default\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,2],[32,3],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid grid-cols-1 gap-2 text-xs dark:text-gray-100\"],[12],[2,\"\\n            \"],[8,\"ui/input-group\",[],[[\"@name\",\"@value\"],[\"Select Order\",[32,3,[\"selectedOrder\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"ui/model-select\",[],[[\"@modelName\",\"@selectedModel\",\"@placeholder\",\"@triggerClass\",\"@infiniteScroll\",\"@renderInPlace\",\"@onChange\"],[\"order\",[32,3,[\"selectedOrder\"]],\"Select Order to Assign\",\"form-select form-input\",false,true,[30,[36,1],[[30,[36,0],[[32,3,[\"selectedOrder\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[10,\"span\"],[14,0,\"uppercase\"],[12],[1,[32,1,[\"public_id\"]]],[2,\" - \"],[1,[32,1,[\"createdAt\"]]],[13],[2,\"\\n                \"]],\"parameters\":[1]}]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[18,6,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/driver-assign-order.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});