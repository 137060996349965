define("@fleetbase/console/utils/waypoint-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = waypointLabel;

  function waypointLabel(index) {
    return (index + 9).toString(36).toUpperCase();
  }
});