define("@fleetbase/console/models/modal", ["exports", "ember-modal-service/models/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modal.default.extend({
    /**
     * Full name for building controllers and views.
     *
     * @property fullname
     * @type String
     */
    fullname: Ember.computed('name', function () {
      const name = this.name;

      if (Ember.isBlank(name)) {
        throw new Error('Modal must have a name.');
      }

      return `modals/${Ember.String.dasherize(name)}`;
    })
  });

  _exports.default = _default;
});