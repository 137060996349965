define("@fleetbase/console/utils/get-permission-resource", ["exports", "@fleetbase/console/utils/get-permission-action", "ember-inflector"], function (_exports, _getPermissionAction, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getPermissionResource;

  function getPermissionResource() {
    let permissionName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    if (permissionName.startsWith('auth')) {
      return 'N/A';
    }

    const permissionNameParts = permissionName.split(':');
    const fullActionName = permissionNameParts.lastObject;
    const actionName = (0, _getPermissionAction.default)(permissionName);
    const resourceName = fullActionName.replace(actionName, '');
    return (0, _emberInflector.singularize)(Ember.String.classify(resourceName));
  }
});