define("@fleetbase/console/helpers/format-string", ["exports", "@fleetbase/ui/helpers/format-string"], function (_exports, _formatString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _formatString.default;
    }
  });
  Object.defineProperty(_exports, "formatString", {
    enumerable: true,
    get: function () {
      return _formatString.formatString;
    }
  });
});