define("@fleetbase/console/routes/operations/orders/index/view", ["exports", "fleet-ops/routes/operations/orders/index/view"], function (_exports, _view) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _view.default;
    }
  });
});