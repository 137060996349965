define("@fleetbase/console/components/widget/app-browser", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "VnC7jd8X",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/widget/app-browser.hbs"
    }
  });

  class WidgetAppBrowserComponent extends _component.default {}

  _exports.default = WidgetAppBrowserComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, WidgetAppBrowserComponent);
});