define("@fleetbase/console/routes/invite/for-driver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InviteForDriverRoute extends Ember.Route {}

  _exports.default = InviteForDriverRoute;
});