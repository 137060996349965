define("@fleetbase/console/components/cell/media-name", ["exports", "@fleetbase/ui/components/cell/media-name"], function (_exports, _mediaName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mediaName.default;
    }
  });
});