define("@fleetbase/console/routes/auth/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AuthLogoutRoute extends Ember.Route {
    /**
     * On activate invalidate the session
     *
     * @void
     */
    activate() {
      this.session.invalidate();
    }

  }

  _exports.default = AuthLogoutRoute;
});