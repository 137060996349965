define("@fleetbase/console/components/modals/extension-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      
  </Ui::Modal>
  */
  {
    "id": "T4p4d6ZJ",
    "block": "{\"symbols\":[\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,1],[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/extension-details.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});