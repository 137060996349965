define("@fleetbase/console/routes/invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InviteRoute extends Ember.Route {}

  _exports.default = InviteRoute;
});