define("@fleetbase/console/controllers/console/account/billing", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ConsoleAccountBillingController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember.computed.not('model.isSubscribed'), _dec13 = Ember.computed('model.priceOptions', 'selectedInterval'), _dec14 = Ember.computed('priceOptions.[]', 'price.id'), _dec15 = Ember.computed.not('swapEnabled'), _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, (_class = class ConsoleAccountBillingController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "stripev3", _descriptor3, this);

      _initializerDefineProperty(this, "currentUser", _descriptor4, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor5, this);

      _initializerDefineProperty(this, "isLoading", _descriptor6, this);

      _initializerDefineProperty(this, "isCanceling", _descriptor7, this);

      _initializerDefineProperty(this, "_price", _descriptor8, this);

      _initializerDefineProperty(this, "_paymentMethods", _descriptor9, this);

      _initializerDefineProperty(this, "selectedInterval", _descriptor10, this);

      _initializerDefineProperty(this, "paymentMethodPanelActions", _descriptor11, this);

      _initializerDefineProperty(this, "isNotSubscribed", _descriptor12, this);

      _initializerDefineProperty(this, "swapDisabled", _descriptor13, this);

      _defineProperty(this, "stripeElementOptions", {
        style: {
          base: {
            color: '#ffffff',
            lineHeight: '1.5',
            iconColor: '#9fa6b2',
            fontSize: '16px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: '#9fa6b2'
            }
          },
          invalid: {
            iconColor: '#F87171',
            color: '#F87171'
          }
        }
      });
    }

    get trialDaysRemaining() {
      return (0, _dateFns.formatDistanceToNow)(new Date(this.model.trialEndsAt));
    }

    get priceOptions() {
      // sort price options by cheapest tier first
      return this.model.priceOptions.filter(price => price.billing_scheme === 'tiered').filter(price => price.recurring.interval === this.selectedInterval).sort((a, b) => {
        return a.tiers.firstObject.flat_amount - b.tiers.firstObject.flat_amount;
      });
    }

    get price() {
      if (this._price) {
        return this._price;
      }

      return this.priceOptions.find(price => price.isSubscribed === true);
    }

    set price(price) {
      this._price = price;
    }

    get paymentMethods() {
      if (this._paymentMethods) {
        return this._paymentMethods;
      }

      return this.model.paymentMethods || [];
    }

    set paymentMethods(paymentMethods) {
      this._paymentMethods = paymentMethods;
    }

    get swapEnabled() {
      return this.priceOptions.find(price => {
        var _this$price;

        const isSubscribed = price.isSubscribed;
        const isSelected = price.id === (this === null || this === void 0 ? void 0 : (_this$price = this.price) === null || _this$price === void 0 ? void 0 : _this$price.id);
        return !isSubscribed && isSelected;
      });
    }

    setPriceOption(price) {
      this.price = price;
      this.notifyPropertyChange('swapEnabled');
    }

    createNewSubscription() {
      const price = this.price;

      if (!price) {
        return this.notifications.warning('Make sure to select a plan to subscribe to.');
      } // if no payment methods prompt to create one


      if (!this.paymentMethods.length) {
        return this.addPaymentMethod({
          onConfirm: this.createNewSubscription
        });
      }

      const paymentMethod = this.paymentMethods.find(paymentMethod => paymentMethod.isDefault === true); // if no default payment method but payment methods available primpt to select

      if (!paymentMethod) {
        return this.selectPaymentMethod({
          onConfirm: this.createNewSubscription
        });
      } // start loading & create new subscription using payment method


      this.isLoading = true;
      return this.fetch.post('billing/create-subscription', {
        paymentMethodId: paymentMethod.id,
        priceId: price.id
      }).then(response => {
        if (response.status === 'success') {
          this.notifications.success('Subscription started, services activated.');
          window.tap('create', '23067-beb221', {
            integration: 'stripe'
          });
          window.tap('conversion', response.charge_id, response.amount_paid, {
            customer_id: response.customer_id
          });
        }

        setTimeout(() => {
          window.location.reload();
        }, 600);
        this.isLoading = false;
      }).catch(error => {
        this.notifications.serverError(error);
        this.isLoading = false;
      });
    }

    updateSubscription() {
      const {
        price
      } = this;
      this.modalsManager.confirm({
        title: `Are you sure you want to change your subscription to ${price.nickname}?`,
        body: `Proceeding will swap your subscription plan to ${price.nickname}, at the end of this billing cycle your subscription will be adjusted and you will be charged the prorated amount.`,
        acceptButtonText: `Yes, I want to change to ${price.nickname}`,
        acceptButtonScheme: 'primary',
        confirm: modal => {
          modal.startLoading();
          this.isLoading = true;
          return this.fetch.put('billing/swap-subscription', {
            priceId: price.id
          }).then(response => {
            if (response.status === 'success') {
              this.notifications.success('Your subscription has been updated.');
            }

            setTimeout(() => {
              window.location.reload();
            }, 600);
            this.isLoading = false;
          }).catch(error => {
            this.notifications.serverError(error);
            this.isLoading = false;
          });
        }
      });
    }

    cancelSubscription() {
      this.modalsManager.confirm({
        title: 'Are you sure you wish to cancel your subscription?',
        body: 'Proceeding will cancel subscription, if you have days remaining you will still be able to access Fleetbase services or resume your subscription during the grace period.',
        confirm: modal => {
          modal.startLoading();
          this.isCanceling = true;
          return this.fetch.delete('billing/cancel-subscription').then(response => {
            if (response.status === 'success') {
              this.notifications.success('Your subscription has been canceled.');
            }

            setTimeout(() => {
              window.location.reload();
            }, 600);
            this.isCanceling = false;
          }).catch(error => {
            this.notifications.serverError(error);
            this.isCanceling = false;
          });
        }
      });
    }

    resumeSubscription() {
      this.modalsManager.confirm({
        title: 'Are you sure you wish to resume your subscription?',
        body: 'Proceeding will resume subscription, since you are within the grace period this means you will be billed at the end of the billing cycle and your subscription will resume as normal.',
        confirm: modal => {
          modal.startLoading();
          this.isLoading = true;
          return this.fetch.post('billing/resume-subscription').then(response => {
            if (response.status === 'success') {
              this.notifications.success('Your subscription has been resumed!');
            }

            setTimeout(() => {
              window.location.reload();
            }, 600);
            this.isLoading = false;
          }).catch(error => {
            this.notifications.serverError(error);
            this.isLoading = false;
          });
        }
      });
    }

    removePaymentMethod(paymentMethod) {
      let isDefault = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (isDefault) {
        return this.notifications.warning('You need atleast one default payment method!');
      }

      this.modalsManager.confirm({
        title: 'Are you sure you wish to delete this payment method?',
        body: 'Proceeding will delete this payment method.',
        confirm: modal => {
          modal.startLoading();
          return this.fetch.delete('billing/remove-payment-method', {
            paymentMethod
          }).then(() => {
            // update payment methods
            this.paymentMethods = this.paymentMethods.filter(pm => {
              return pm.id !== paymentMethod;
            });
          });
        }
      });
    }

    makePaymentMethodDefault(paymentMethod) {
      Ember.set(paymentMethod, 'isLoading', true);
      return this.fetch.put('billing/update-default-payment-method', {
        paymentMethod: paymentMethod.id
      }).then(() => {
        this.paymentMethods = this.paymentMethods.map(pm => {
          Ember.set(pm, 'isDefault', false);

          if (pm.id === paymentMethod.id) {
            Ember.set(pm, 'isDefault', true);
          }

          return pm;
        });
        Ember.set(paymentMethod, 'isLoading', false);
      }).catch(error => {
        this.notifications.serverError(error);
        Ember.set(paymentMethod, 'isLoading', false);
      });
    }

    addPaymentMethod() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.modalsManager.show('modals/add-payment-method', {
        title: 'Add a new payment method',
        acceptButtonText: 'Save as new payment method',
        stripeElementOptions: this.stripeElementOptions,
        isFirstPaymentMethod: this.paymentMethods.length === 0,
        isValid: false,
        isDefault: this.paymentMethods.length === 0,
        setStripeElement: stripeElement => {
          this.modalsManager.setOption('stripeElement', stripeElement);
          this.modalsManager.setOption('acceptButtonDisabled', false);
          this.modalsManager.setOption('isValid', true);
        },
        validateInput: (event, stripeElement) => {
          if (stripeElement.error) {
            this.modalsManager.setOption('isValid', false);
            this.modalsManager.setOption('acceptButtonDisabled', true);
          } else {
            this.modalsManager.setOption('acceptButtonDisabled', false);
          }
        },
        invalidateInput: () => {
          this.modalsManager.setOption('isValid', false);
          this.modalsManager.setOption('acceptButtonDisabled', true);
        },
        confirm: modal => {
          modal.startLoading();
          const stripeElement = modal.getOption('stripeElement');
          const isDefault = modal.getOption('isDefault');
          return this.stripev3.createPaymentMethod({
            type: 'card',
            card: stripeElement
          }).then(result => {
            if (result.error) {
              this.isLoading = false;
              this.notifications.error(result.error.message);
              return;
            } // save server side


            const {
              paymentMethod
            } = result;
            return this.fetch.post('billing/save-payment-method', {
              paymentMethod: paymentMethod.id,
              isDefault
            }).then(() => {
              Ember.setProperties(paymentMethod, {
                isDefault,
                ...paymentMethod.card
              });

              if (isDefault) {
                this.paymentMethods = this.paymentMethods.map(pm => {
                  Ember.set(pm, 'isDefault', false);
                  return pm;
                });
              }

              this.paymentMethods.pushObject(paymentMethod);
            });
          }).catch(error => {
            modal.stopLoading();
            this.notifications.error(error.message);
          });
        },
        ...options
      });
    }

    selectPaymentMethod() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.modalsManager.show('modals/add-payment-method', {
        title: 'Select a payment method to use',
        acceptButtonText: 'Save as default payment method & continue',
        paymentMethods: this.paymentMethods,
        selectedPaymentMethod: null,
        selectPaymentMethod: paymentMethod => {
          this.modalsManager.setOption('selectedPaymentMethod', paymentMethod);
        },
        addPaymentMethod: () => {
          return this.addPaymentMethod({
            onFinish: this.selectPaymentMethod
          });
        },
        confirm: modal => {
          modal.startLoading();
          const selectedPaymentMethod = modal.getOption('selectedPaymentMethod');
          return this.makePaymentMethodDefault(selectedPaymentMethod);
        },
        ...options
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stripev3", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isCanceling", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_price", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_paymentMethods", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedInterval", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'month';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "paymentMethodPanelActions", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        icon: 'credit-card-front',
        text: 'Add a payment method',
        onClick: this.addPaymentMethod
      }];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isNotSubscribed", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "priceOptions", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "priceOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "swapEnabled", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "swapEnabled"), _class.prototype), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "swapDisabled", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setPriceOption", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setPriceOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createNewSubscription", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "createNewSubscription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSubscription", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "updateSubscription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelSubscription", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSubscription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resumeSubscription", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "resumeSubscription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removePaymentMethod", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "removePaymentMethod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "makePaymentMethodDefault", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "makePaymentMethodDefault"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addPaymentMethod", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "addPaymentMethod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectPaymentMethod", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "selectPaymentMethod"), _class.prototype)), _class));
  _exports.default = ConsoleAccountBillingController;
});