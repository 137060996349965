define("@fleetbase/console/utils/is-model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isModel;

  function isModel(mixed) {
    return mixed instanceof _model.default || mixed instanceof Ember.ObjectProxy;
  }
});