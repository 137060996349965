define("@fleetbase/console/services/app-cache", ["exports", "ember-local-storage", "@fleetbase/console/utils/auto-serialize"], function (_exports, _emberLocalStorage, _autoSerialize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AppCacheService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberLocalStorage.storageFor)('local-cache'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class AppCacheService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "localCache", _descriptor3, this);
    }

    get cachePrefix() {
      return `${this.currentUser.id}:${this.currentUser.companyId}:`;
    }

    setEmberData(key, value) {
      value = (0, _autoSerialize.default)(value);
      return this.set(key, value);
    }

    getEmberData(key, modelName) {
      const data = this.get(key);
      console.log(' [ getEmberData() ] ', key, modelName);

      if (Ember.isArray(data)) {
        return data.map(instance => this.store.push(this.store.normalize(modelName, instance)));
      }

      return this.store.push(this.store.normalize(modelName, data));
    }

    set(key, value) {
      this.localCache.set(`${this.cachePrefix}${Ember.String.dasherize(key)}`, value);
      return this;
    }

    get(key) {
      return this.localCache.get(`${this.cachePrefix}${Ember.String.dasherize(key)}`);
    }

    has(key) {
      return this.get(key) !== undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "localCache", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setEmberData", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setEmberData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getEmberData", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "getEmberData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "set", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "set"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "get", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "get"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "has", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "has"), _class.prototype)), _class));
  _exports.default = AppCacheService;
});