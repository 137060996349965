define("@fleetbase/console/helpers/is-not-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function isNotEmpty(_ref) {
    let [mixed] = _ref;
    return !Ember.isEmpty(mixed);
  });

  _exports.default = _default;
});