define("@fleetbase/console/utils/is-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isObject;

  function isObject(obj) {
    return typeof obj === 'object';
  }
});