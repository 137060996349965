define("@fleetbase/console/utils/is-proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isProxy;

  function isProxy(subject) {
    return subject instanceof Ember.ObjectProxy;
  }
});