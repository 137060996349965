define("@fleetbase/console/helpers/stripe-price-included", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function stripePriceIncluded(_ref) {
    let [price, unit = 'order'] = _ref;
    const limit = price.tiers.firstObject.up_to; // hack for enterprise

    if (price.nickname.includes('Enterprise')) {
      return `Unlimited ${(0, _emberInflector.pluralize)(unit)}`;
    }

    return `${(0, _emberInflector.pluralize)(limit, unit)} included`;
  });

  _exports.default = _default;
});