define("@fleetbase/console/components/modals/order-assign-driver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          <div class="grid grid-cols-1 gap-2 text-xs dark:text-gray-100">
              <Ui::InputGroup @name="Select Driver">
                  <Ui::ModelSelect
                      @modelName="driver"
                      @selectedModel={{@options.order.driver_assigned}}
                      @placeholder="Select Driver to Assign"
                      @triggerClass="form-select form-input"
                      @infiniteScroll={{false}}
                      @renderInPlace={{true}}
                      @onChange={{fn (mut @options.order.driver_assigned)}} as |model|
                  >
                      {{model.name}}
                  </Ui::ModelSelect>
              </Ui::InputGroup>
          </div>
      </div>
      {{yield}}
  </Ui::Modal>
  */
  {
    "id": "ObqHdrih",
    "block": "{\"symbols\":[\"model\",\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\",\"&default\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,2],[32,3],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid grid-cols-1 gap-2 text-xs dark:text-gray-100\"],[12],[2,\"\\n            \"],[8,\"ui/input-group\",[],[[\"@name\"],[\"Select Driver\"]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"ui/model-select\",[],[[\"@modelName\",\"@selectedModel\",\"@placeholder\",\"@triggerClass\",\"@infiniteScroll\",\"@renderInPlace\",\"@onChange\"],[\"driver\",[32,3,[\"order\",\"driver_assigned\"]],\"Select Driver to Assign\",\"form-select form-input\",false,true,[30,[36,1],[[30,[36,0],[[32,3,[\"order\",\"driver_assigned\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[1,[32,1,[\"name\"]]],[2,\"\\n                \"]],\"parameters\":[1]}]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[18,6,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/order-assign-driver.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});