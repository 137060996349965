define("@fleetbase/console/controllers/onboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OnboardController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, (_class = class OnboardController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "name", _descriptor3, this);

      _initializerDefineProperty(this, "company_name", _descriptor4, this);

      _initializerDefineProperty(this, "password", _descriptor5, this);

      _initializerDefineProperty(this, "password_confirmation", _descriptor6, this);

      _initializerDefineProperty(this, "isLoading", _descriptor7, this);
    }

    async signUp() {
      // validate password confirmation
      if (!this.validatePassword()) {
        return;
      } // get user credentials


      const {
        name,
        email,
        company_name,
        password,
        password_confirmation
      } = this; // user details

      const user = {
        name,
        email,
        password,
        password_confirmation
      }; // company details

      const company = {
        name: company_name
      }; // start loader

      this.set('isLoading', true);

      try {
        await this.session.onboard('authenticator:fleetbase', user, company);
      } catch (error) {
        return this.failure(error);
      }

      if (this.session.isAuthenticated) {
        console.log('isAuthenticated', this.session.isAuthenticated);
        this.success();
      }
    }

    transitionToLogin() {
      return this.transitionToRoute('auth.login');
    }

    validatePassword() {
      const valid = this.password === this.password_confirmation;

      if (!valid) {
        this.notifications.warning(`Password's do not match!`);
      }

      return valid;
    }

    /**
     * Handles the authentication success
     *
     * @void
     */
    success() {
      this.reset('success');
    }
    /**
     * Handles the authentication failure
     *
     * @param {String} error An error message
     * @void
     */


    failure(error) {
      this.notifications.serverError(error);
      this.reset('error');
    }
    /**
     * Reset the login form
     *
     * @param {String} type
     * @void
     */


    reset(type) {
      // reset login form state
      this.isLoading = false;
      this.isSlowConnection = false; // reset login form state depending on type of reset

      switch (type) {
        case 'success':
          this.name = null;
          this.email = null;
          this.password = null;
          this.passwordConfirmation = null;
          break;

        case 'error':
        case 'fail':
          this.passwordConfirmation = null;
          break;
      } // clearTimeout(this.timeout);

    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "signUp", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "signUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToLogin", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToLogin"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "company_name", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "password", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "password_confirmation", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OnboardController;
});