define("@fleetbase/console/components/modals/add-payment-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          <Ui::InputGroup @wrapperClass="mb-6" @name="Card Details" @helpText="Your credit card details, make sure to double check card number, CVC, and expiration date.">
              <div class="form-input input-lg">
                  <StripeCard @options={{@options.stripeElementOptions}} @onComplete={{fn @options.setStripeElement}} @onChange={{fn @options.validateInput}} @onError={{fn @options.invalidateInput}} />
              </div>
          </Ui::InputGroup>
          <div class="input-group">
              <Ui::Checkbox @value={{@options.isDefault}} @label="Make default payment method" @onToggle={{fn (mut @options.isDefault)}} @disabled={{@options.isFirstPaymentMethod}} />
          </div>
      </div>
  </Ui::Modal>
  
  */
  {
    "id": "SQ8BgdO0",
    "block": "{\"symbols\":[\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,1],[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[8,\"ui/input-group\",[],[[\"@wrapperClass\",\"@name\",\"@helpText\"],[\"mb-6\",\"Card Details\",\"Your credit card details, make sure to double check card number, CVC, and expiration date.\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"form-input input-lg\"],[12],[2,\"\\n                \"],[8,\"stripe-card\",[],[[\"@options\",\"@onComplete\",\"@onChange\",\"@onError\"],[[32,2,[\"stripeElementOptions\"]],[30,[36,0],[[32,2,[\"setStripeElement\"]]],null],[30,[36,0],[[32,2,[\"validateInput\"]]],null],[30,[36,0],[[32,2,[\"invalidateInput\"]]],null]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n            \"],[8,\"ui/checkbox\",[],[[\"@value\",\"@label\",\"@onToggle\",\"@disabled\"],[[32,2,[\"isDefault\"]],\"Make default payment method\",[30,[36,0],[[30,[36,1],[[32,2,[\"isDefault\"]]],null]],null],[32,2,[\"isFirstPaymentMethod\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"mut\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/add-payment-method.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});