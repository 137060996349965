define("@fleetbase/console/routes/auth/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AuthLoginRoute extends Ember.Route {
    /**
     * If user is authentication redirect to console
     *
     * @void
     */
    beforeModel() {
      this.session.prohibitAuthentication('console'); // this.fetch.setupXsrf();
    }

  }

  _exports.default = AuthLoginRoute;
});