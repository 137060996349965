define("@fleetbase/console/components/modals-container", ["exports", "ember-bootstrap-modals-manager/components/modals-container"], function (_exports, _modalsContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.modalIsOpened}}
      {{component this.componentName modalIsOpened=this.modalIsOpened options=this.options service=this.modalsManager onConfirm=(fn this.confirm) onDecline=(fn this.decline)}}
  {{/if}}
  {{yield}}
  */
  {
    "id": "tQ9rK3Os",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[32,0,[\"modalIsOpened\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,0,[\"componentName\"]]],[[\"modalIsOpened\",\"options\",\"service\",\"onConfirm\",\"onDecline\"],[[32,0,[\"modalIsOpened\"]],[32,0,[\"options\"]],[32,0,[\"modalsManager\"]],[30,[36,0],[[32,0,[\"confirm\"]]],null],[30,[36,0],[[32,0,[\"decline\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"fn\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals-container.hbs"
    }
  });

  let ModalsContainerComponent = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember.computed.alias('modalsManager.options'), (_class = class ModalsContainerComponent extends _modalsContainer.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "options", _descriptor, this);
    }

    confirm(v) {
      this.modalsManager.onClickConfirmWithDone(v);
    }

    decline(v) {
      this.modalsManager.onClickDeclineWithDone(v);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "confirm", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "confirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "decline", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "decline"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "options", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ModalsContainerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ModalsContainerComponent);
});