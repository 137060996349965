define("@fleetbase/console/controllers/console/extensions/index", ["exports", "socketcluster"], function (_exports, _socketcluster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ConsoleExtensionsIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class ConsoleExtensionsIndexController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "fetch", _descriptor2, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor3, this);

      _initializerDefineProperty(this, "notifications", _descriptor4, this);

      _initializerDefineProperty(this, "queryParams", _descriptor5, this);

      _initializerDefineProperty(this, "sort", _descriptor6, this);

      _initializerDefineProperty(this, "page", _descriptor7, this);

      _initializerDefineProperty(this, "limit", _descriptor8, this);

      _initializerDefineProperty(this, "category", _descriptor9, this);

      _initializerDefineProperty(this, "type", _descriptor10, this);

      _initializerDefineProperty(this, "categories", _descriptor11, this);

      _initializerDefineProperty(this, "types", _descriptor12, this);

      _initializerDefineProperty(this, "installChannel", _descriptor13, this);
    }

    install(extension) {
      const extensionName = extension.display_name || extension.name;
      this.listenForInstallProgress(extension);
      this.modalsManager.show('modals/install-prompt', {
        title: 'Install Extension',
        acceptButtonText: 'Install',
        extension,
        extensionName,
        isInstalling: false,
        installProgress: 0,
        confirm: (modal, done) => {
          modal.startLoading();
          modal.setOption('isInstalling', true);
          extension.install().then(() => {
            this.closeInstallChannel();
            this.notifications.success(`Extension '${extensionName}' installed and activated successfully.`);
            done();
          }).catch(error => {
            modal.stopLoading();
            modal.setOption('isInstalling', false);
            this.notifications.serverError(error);
          });
        },
        decline: modal => {
          this.closeInstallChannel();
          modal.done();
        }
      });
    }

    viewExtensionDetails(extension) {
      const extensionName = extension.display_name || extension.name;
      this.modalsManager.show('modals/extension-details', {
        title: `${extensionName} Extension`,
        acceptButtonText: 'Done',
        hideDeclineButton: true,
        extension,
        extensionName
      });
    }

    async listenForInstallProgress(extension) {
      // get channel identifier
      const channelId = `${extension.id}:${this.currentUser.id}`; // setup socket

      const socket = _socketcluster.default.create({
        hostname: 'socket.fleetbase.io',
        secure: true,
        port: 8000
      }); // get channel


      const channel = socket.subscribe(channelId);
      this.installChannel = channel; // listen to channel for events

      await channel.listener('subscribe').once(); // get incoming data and console out

      for await (let data of channel) {
        if (data.progress) {
          this.modalsManager.setOption('installProgress', data.progress);
        }
      }
    }

    closeInstallChannel() {
      if (this.installChannel) {
        this.installChannel.close();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['page', 'limit', 'sort', 'query', 'category', 'type'];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sort", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '-id';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 30;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "categories", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "types", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "installChannel", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "install", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "install"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewExtensionDetails", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "viewExtensionDetails"), _class.prototype)), _class));
  _exports.default = ConsoleExtensionsIndexController;
});