define("@fleetbase/console/components/modals/view-zone", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="next-map-container">
          <LeafletMap class="h-screen-65 w-full {{@options.mapClass}}" @lat={{this.latitude}} @lng={{this.longitude}} @zoom={{11}} @zoomControl={{false}} @onLoad={{fn this.setupMap}} ...attributes as |layers|>
              <layers.tile @url={{this.tileSourceUrl}} />
              {{#if @options.zone}}
                  <layers.polygon @id={{@options.zone.id}} @record={{@options.zone}} @locations={{@options.zone.locations}} @fillColor={{@options.zone.color}} @color={{@options.zone.stroke_color}} @onAdd={{fn this.onZoneAdded @options.zone}} as |polygon|>
                      <polygon.tooltip @permanent={{true}} @sticky={{true}}>{{@options.zone.name}} Zone</polygon.tooltip>
                  </layers.polygon>
              {{/if}}
          </LeafletMap>
      </div>
  </Ui::Modal>
  */
  {
    "id": "P51N1jSM",
    "block": "{\"symbols\":[\"layers\",\"polygon\",\"@options\",\"@modalIsOpened\",\"@onConfirm\",\"@onDecline\",\"&attrs\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,4],[32,3],[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"next-map-container\"],[12],[2,\"\\n        \"],[8,\"leaflet-map\",[[16,0,[31,[\"h-screen-65 w-full \",[32,3,[\"mapClass\"]]]]],[17,7]],[[\"@lat\",\"@lng\",\"@zoom\",\"@zoomControl\",\"@onLoad\"],[[32,0,[\"latitude\"]],[32,0,[\"longitude\"]],11,false,[30,[36,0],[[32,0,[\"setupMap\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"tile\"]],[],[[\"@url\"],[[32,0,[\"tileSourceUrl\"]]]],null],[2,\"\\n\"],[6,[37,1],[[32,3,[\"zone\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,1,[\"polygon\"]],[],[[\"@id\",\"@record\",\"@locations\",\"@fillColor\",\"@color\",\"@onAdd\"],[[32,3,[\"zone\",\"id\"]],[32,3,[\"zone\"]],[32,3,[\"zone\",\"locations\"]],[32,3,[\"zone\",\"color\"]],[32,3,[\"zone\",\"stroke_color\"]],[30,[36,0],[[32,0,[\"onZoneAdded\"]],[32,3,[\"zone\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[8,[32,2,[\"tooltip\"]],[],[[\"@permanent\",\"@sticky\"],[true,true]],[[\"default\"],[{\"statements\":[[1,[32,3,[\"zone\",\"name\"]]],[2,\" Zone\"]],\"parameters\":[]}]]],[2,\"\\n                \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/view-zone.hbs"
    }
  });

  let ModalsViewZoneComponent = (_dec = Ember._tracked, _dec2 = Ember.computed.alias('args.options.zone'), _dec3 = Ember.computed.alias('zone.centerCoordinates.0'), _dec4 = Ember.computed.alias('zone.centerCoordinates.1'), _dec5 = Ember.computed('args.{tileSourceUrl,darkMode}'), _dec6 = Ember._action, _dec7 = Ember._action, (_class = class ModalsViewZoneComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "leafletMap", _descriptor, this);

      _initializerDefineProperty(this, "zone", _descriptor2, this);

      _initializerDefineProperty(this, "latitude", _descriptor3, this);

      _initializerDefineProperty(this, "longitude", _descriptor4, this);
    }

    get tileSourceUrl() {
      const {
        darkMode,
        tileSourceUrl
      } = this.args;

      if (darkMode === true) {
        return 'https://{s}.tile.jawg.io/jawg-matrix/{z}/{x}/{y}{r}.png?access-token=';
      }

      return tileSourceUrl ?? 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png';
    }

    async setupMap(_ref2) {
      let {
        target
      } = _ref2;
      this.leafletMap = target;
    }

    onZoneAdded(zone, _ref3) {
      var _this$leafletMap, _this$leafletMap2;

      let {
        target
      } = _ref3;
      const bounds = target === null || target === void 0 ? void 0 : target.getBounds();
      (_this$leafletMap = this.leafletMap) === null || _this$leafletMap === void 0 ? void 0 : _this$leafletMap.flyToBounds(bounds);
      (_this$leafletMap2 = this.leafletMap) === null || _this$leafletMap2 === void 0 ? void 0 : _this$leafletMap2.setMaxBounds(bounds);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "leafletMap", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "zone", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "latitude", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "longitude", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "tileSourceUrl", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "tileSourceUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupMap", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setupMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onZoneAdded", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onZoneAdded"), _class.prototype)), _class));
  _exports.default = ModalsViewZoneComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ModalsViewZoneComponent);
});