define("@fleetbase/console/transforms/array", ["exports", "@ember-data/serializer/transform", "@fleetbase/console/utils/is-iterable"], function (_exports, _transform, _isIterable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ArrayTransform extends _transform.default {
    deserialize(serialized) {
      if (Ember.isArray(serialized)) {
        return serialized;
      }

      if (!(0, _isIterable.default)(serialized)) {
        return [];
      }

      return Array.from(serialized);
    }

    serialize(deserialized) {
      if (Ember.isArray(deserialized)) {
        return deserialized;
      }

      if (!(0, _isIterable.default)(deserialized)) {
        return [];
      }

      return Array.from(deserialized);
    }

  }

  _exports.default = ArrayTransform;
});