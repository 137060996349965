define("@fleetbase/console/utils/with-default-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = withDefaultValue;

  function withDefaultValue(value) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'N/A';
    return Ember.isBlank(value) ? defaultValue : value;
  }
});