define("@fleetbase/console/components/next/driver-chat-panel", ["exports", "@fleetbase/ui/components/next/driver-chat-panel"], function (_exports, _driverChatPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _driverChatPanel.default;
    }
  });
});