define("@fleetbase/console/helpers/n-a", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function nA(_ref) {
    let [value, defaultValue = 'N/A'] = _ref;
    return Ember.isBlank(value) ? defaultValue : value;
  });

  _exports.default = _default;
});