define("@fleetbase/console/components/cell/click-to-reveal", ["exports", "@fleetbase/ui/components/cell/click-to-reveal"], function (_exports, _clickToReveal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _clickToReveal.default;
    }
  });
});