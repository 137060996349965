define("@fleetbase/console/components/progress-bar", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="relative pt-1" ...attributes>
      <div class="flex mb-2 items-center justify-between">
          <div>
              <span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-{{this.color}}-600 bg-{{this.color}}-200">
                  {{this.task}}
              </span>
          </div>
          <div class="text-right">
              <span class="text-xs font-semibold inline-block text-{{this.color}}-600">
                  {{this.displayProgress}}
              </span>
          </div>
      </div>
      <div class="progress-bar-container overflow-hidden h-2 mb-4 text-xs flex rounded bg-{{this.color}}-200">
          <div style={{this.inlineProgress}} class="progress-bar shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-{{this.color}}-500"></div>
      </div>
  </div>
  */
  {
    "id": "NtpCtqJh",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"relative pt-1\"],[17,1],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex mb-2 items-center justify-between\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[31,[\"text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-\",[32,0,[\"color\"]],\"-600 bg-\",[32,0,[\"color\"]],\"-200\"]]],[12],[2,\"\\n                \"],[1,[32,0,[\"task\"]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[31,[\"text-xs font-semibold inline-block text-\",[32,0,[\"color\"]],\"-600\"]]],[12],[2,\"\\n                \"],[1,[32,0,[\"displayProgress\"]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"progress-bar-container overflow-hidden h-2 mb-4 text-xs flex rounded bg-\",[32,0,[\"color\"]],\"-200\"]]],[12],[2,\"\\n        \"],[10,\"div\"],[15,5,[32,0,[\"inlineProgress\"]]],[15,0,[31,[\"progress-bar shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-\",[32,0,[\"color\"]],\"-500\"]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/progress-bar.hbs"
    }
  });

  let ProgressBarComponent = (_dec = Ember.computed('args.color'), _dec2 = Ember.computed('args.task'), _dec3 = Ember.computed('args.progress'), _dec4 = Ember.computed('args.progress'), (_class = class ProgressBarComponent extends _component.default {
    get color() {
      return this.args.color || 'blue';
    }

    get task() {
      return this.args.task ? `${Ember.String.capitalize(this.args.task)} in progress` : 'Task in progress';
    }

    get displayProgress() {
      const {
        progress
      } = this.args;
      return `${Math.round(progress)}%`;
    }

    get inlineProgress() {
      const {
        progress
      } = this.args;
      return Ember.String.htmlSafe(`width: ${Math.round(progress)}%`);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "color", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "color"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "task", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "task"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayProgress", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "displayProgress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inlineProgress", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "inlineProgress"), _class.prototype)), _class));
  _exports.default = ProgressBarComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ProgressBarComponent);
});