define("@fleetbase/console/components/full-calendar/draggable", ["exports", "@glimmer/component", "@fullcalendar/interaction"], function (_exports, _component, _interaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ui-calendar-draggable" data-event={{@eventData}} {{did-insert (fn this.makeDraggable)}} ...attributes>
      {{yield}}
  </div>
  */
  {
    "id": "SosJyksy",
    "block": "{\"symbols\":[\"@eventData\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"ui-calendar-draggable\"],[16,\"data-event\",[32,1]],[17,2],[4,[38,1],[[30,[36,0],[[32,0,[\"makeDraggable\"]]],null]],null],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/full-calendar/draggable.hbs"
    }
  });

  let FullCalendarDraggableComponent = (_dec = Ember._action, (_class = class FullCalendarDraggableComponent extends _component.default {
    makeDraggable(element) {
      new _interaction.Draggable(element);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "makeDraggable", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "makeDraggable"), _class.prototype)), _class));
  _exports.default = FullCalendarDraggableComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FullCalendarDraggableComponent);
});