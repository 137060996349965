define("@fleetbase/console/serializers/user", ["exports", "@fleetbase/console/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserSerializer extends _application.default {}

  _exports.default = UserSerializer;
});