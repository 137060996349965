define("@fleetbase/console/templates/invite/for-network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fjFJp7L1",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"flex items-center justify-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"mx-10 my-12\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"rounded-md border dark:border-gray-900 shadow-sm\"],[12],[2,\"\\n                \"],[11,\"div\"],[24,0,\"flex items-center justify-center rounded-t-md h-48\"],[4,[38,0],[[32,1,[\"backdrop_url\"]]],null],[12],[2,\"\\n                    \"],[10,\"img\"],[15,\"src\",[32,1,[\"logo_url\"]]],[14,0,\"w-36\"],[15,\"alt\",[30,[36,1],[[32,1,[\"name\"]],\" logo\"],null]],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"bg-gray-100 dark:bg-gray-200 p-4 rounded-b-md\"],[12],[2,\"\\n                    \"],[10,\"div\"],[12],[2,\"\\n                        \"],[10,\"p\"],[14,0,\"text-gray-600 text-lg mb-2\"],[12],[2,\"You've been invited to join the \"],[1,[32,1,[\"name\"]]],[2,\" network.\"],[13],[2,\"\\n                        \"],[10,\"p\"],[14,0,\"text-gray-500\"],[12],[2,\"Complete the information below to join this network.\"],[13],[2,\"\\n                    \"],[13],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"flex items-center justify-center py-4\"],[12],[2,\"\\n                        \"],[10,\"span\"],[14,0,\"text-xs text-gray-800\"],[12],[2,\"© \"],[1,[30,[36,3],[[30,[36,2],null,null],\"yyyy\"],null]],[2,\" \"],[1,[32,1,[\"name\"]]],[2,\". All Rights Reserved.\"],[13],[2,\"\\n                    \"],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"background-url\",\"concat\",\"date\",\"date-format\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/templates/invite/for-network.hbs"
    }
  });

  _exports.default = _default;
});