define("@fleetbase/console/models/component", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Component = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('raw'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('raw'), _dec22 = (0, _model.attr)('raw'), _dec23 = (0, _model.attr)('raw'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('string'), _dec28 = (0, _model.attr)('string'), _dec29 = (0, _model.attr)('date'), _dec30 = (0, _model.attr)('date'), _dec31 = (0, _model.attr)('date'), _dec32 = Ember.computed('updated_at'), _dec33 = Ember.computed('updated_at'), _dec34 = Ember.computed('created_at'), _dec35 = Ember.computed('created_at'), (_class = class Component extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "uuid", _descriptor, this);

      _initializerDefineProperty(this, "public_id", _descriptor2, this);

      _initializerDefineProperty(this, "component_id", _descriptor3, this);

      _initializerDefineProperty(this, "author_uuid", _descriptor4, this);

      _initializerDefineProperty(this, "category_uuid", _descriptor5, this);

      _initializerDefineProperty(this, "type_uuid", _descriptor6, this);

      _initializerDefineProperty(this, "icon_uuid", _descriptor7, this);

      _initializerDefineProperty(this, "icon_url", _descriptor8, this);

      _initializerDefineProperty(this, "namespace", _descriptor9, this);

      _initializerDefineProperty(this, "name", _descriptor10, this);

      _initializerDefineProperty(this, "author_name", _descriptor11, this);

      _initializerDefineProperty(this, "category_name", _descriptor12, this);

      _initializerDefineProperty(this, "description", _descriptor13, this);

      _initializerDefineProperty(this, "version", _descriptor14, this);

      _initializerDefineProperty(this, "tags", _descriptor15, this);

      _initializerDefineProperty(this, "component_url", _descriptor16, this);

      _initializerDefineProperty(this, "website_url", _descriptor17, this);

      _initializerDefineProperty(this, "privacy_policy_url", _descriptor18, this);

      _initializerDefineProperty(this, "tos_url", _descriptor19, this);

      _initializerDefineProperty(this, "contact_email", _descriptor20, this);

      _initializerDefineProperty(this, "domains", _descriptor21, this);

      _initializerDefineProperty(this, "meta", _descriptor22, this);

      _initializerDefineProperty(this, "config", _descriptor23, this);

      _initializerDefineProperty(this, "secret", _descriptor24, this);

      _initializerDefineProperty(this, "client_token", _descriptor25, this);

      _initializerDefineProperty(this, "installs_count", _descriptor26, this);

      _initializerDefineProperty(this, "slug", _descriptor27, this);

      _initializerDefineProperty(this, "status", _descriptor28, this);

      _initializerDefineProperty(this, "deleted_at", _descriptor29, this);

      _initializerDefineProperty(this, "created_at", _descriptor30, this);

      _initializerDefineProperty(this, "updated_at", _descriptor31, this);
    }

    // additional state of will publishwillPublish = false;
    get updatedAgo() {
      return (0, _moment.default)(this.updated_at).fromNow();
    }

    get updatedAt() {
      return (0, _moment.default)(this.updated_at).format('DD MMM YYYY');
    }

    get createdAgo() {
      return (0, _moment.default)(this.created_at).fromNow();
    }

    get createdAt() {
      return (0, _moment.default)(this.created_at).format('DD MMM YYYY');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "public_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "component_id", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "author_uuid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "category_uuid", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "type_uuid", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "icon_uuid", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "icon_url", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "namespace", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "author_name", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "category_name", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "component_url", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "website_url", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "privacy_policy_url", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "tos_url", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "contact_email", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "domains", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "meta", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "secret", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "client_token", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "installs_count", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec35], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype)), _class));
  _exports.default = Component;
});