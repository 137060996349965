define("@fleetbase/console/helpers/format-point", ["exports", "@fleetbase/console/utils/extract-coordinates"], function (_exports, _extractCoordinates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatPoint(_ref) {
    let [point] = _ref;

    if (Ember.isArray(point)) {
      return `(${point[0]}, ${point[1]})`;
    }

    const [latitude, longitude] = (0, _extractCoordinates.default)(point.coordinates);
    return `(${latitude}, ${longitude})`;
  });

  _exports.default = _default;
});