define("@fleetbase/console/components/leaflet-routing-control", ["exports", "ember-leaflet/components/base-layer"], function (_exports, _baseLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LeafletRoutingControlComponent extends _baseLayer.default {}

  _exports.default = LeafletRoutingControlComponent;
});