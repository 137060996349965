define("@fleetbase/console/modifiers/z-index", ["exports", "@fleetbase/ui/modifiers/z-index"], function (_exports, _zIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _zIndex.default;
    }
  });
});