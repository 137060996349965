define("@fleetbase/console/components/modals/export-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}} as |options|>
      <div class="modal-body-container">
          <div class="input-group">
              <label>
                  Format
              </label>
              <select class="w-full mb-1 form-select" value="xlsx" {{on "change" (fn @options.setFormat)}}>
                  <option selected disabled>
                      Select export format...
                  </option>
                  {{#each @options.formatOptions as |opt|}}
                      <option value={{opt}}>
                          {{uppercase opt}}
                      </option>
                  {{/each}}
              </select>
          </div>
      </div>
  </Ui::Modal>
  */
  {
    "id": "7TmIhRE/",
    "block": "{\"symbols\":[\"options\",\"opt\",\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,3],[32,4],[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n            \"],[10,\"label\"],[12],[2,\"\\n                Format\\n            \"],[13],[2,\"\\n            \"],[11,\"select\"],[24,0,\"w-full mb-1 form-select\"],[24,2,\"xlsx\"],[4,[38,2],[\"change\",[30,[36,1],[[32,4,[\"setFormat\"]]],null]],null],[12],[2,\"\\n                \"],[10,\"option\"],[14,\"selected\",\"\"],[14,\"disabled\",\"\"],[12],[2,\"\\n                    Select export format...\\n                \"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,4,[\"formatOptions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[10,\"option\"],[15,2,[32,2]],[12],[2,\"\\n                        \"],[1,[30,[36,0],[[32,2]],null]],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"uppercase\",\"fn\",\"on\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/export-form.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});