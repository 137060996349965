define("@fleetbase/console/modifiers/set-container-dimensions", ["exports", "@fleetbase/ui/modifiers/set-container-dimensions"], function (_exports, _setContainerDimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _setContainerDimensions.default;
    }
  });
});