define("@fleetbase/console/helpers/date-from-now", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function dateFromNow(_ref) {
    let [date] = _ref;
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _dateFns.formatDistance)(date, new Date(), options);
  });

  _exports.default = _default;
});