define("@fleetbase/console/helpers/json-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function jsonHash(params, hash) {
    let json = JSON.stringify(hash);
    return json;
  });

  _exports.default = _default;
});