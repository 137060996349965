define("@fleetbase/console/models/api-credential", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApiCredential = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('raw'), _dec10 = (0, _model.attr)('date'), _dec11 = (0, _model.attr)('date'), _dec12 = (0, _model.attr)('date'), _dec13 = (0, _model.attr)('date'), _dec14 = (0, _model.attr)('date'), _dec15 = Ember.computed('name', 'key'), _dec16 = Ember.computed('test_mode'), _dec17 = Ember.computed('updated_at'), _dec18 = Ember.computed('updated_at'), _dec19 = Ember.computed('created_at'), _dec20 = Ember.computed('last_used_at'), _dec21 = Ember.computed('expires_at'), _dec22 = Ember.computed('created_at'), (_class = class ApiCredential extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "uuid", _descriptor, this);

      _initializerDefineProperty(this, "user_uuid", _descriptor2, this);

      _initializerDefineProperty(this, "company_uuid", _descriptor3, this);

      _initializerDefineProperty(this, "name", _descriptor4, this);

      _initializerDefineProperty(this, "key", _descriptor5, this);

      _initializerDefineProperty(this, "secret", _descriptor6, this);

      _initializerDefineProperty(this, "test_mode", _descriptor7, this);

      _initializerDefineProperty(this, "api", _descriptor8, this);

      _initializerDefineProperty(this, "browser_origins", _descriptor9, this);

      _initializerDefineProperty(this, "last_used_at", _descriptor10, this);

      _initializerDefineProperty(this, "expires_at", _descriptor11, this);

      _initializerDefineProperty(this, "deleted_at", _descriptor12, this);

      _initializerDefineProperty(this, "created_at", _descriptor13, this);

      _initializerDefineProperty(this, "updated_at", _descriptor14, this);
    }

    get fullName() {
      if (this.name) {
        return `${this.name} (${this.key})`;
      }

      return `${this.key}`;
    }

    get environment() {
      return this.test_mode ? 'Test' : 'Live';
    }

    get updatedAgo() {
      return (0, _moment.default)(this.updated_at).fromNow();
    }

    get updatedAt() {
      return (0, _moment.default)(this.updated_at).format('DD MMM, YYYY');
    }

    get createdAgo() {
      return (0, _moment.default)(this.created_at).fromNow();
    }

    get lastUsed() {
      if (!this.last_used_at) {
        return 'Never used';
      }

      return (0, _moment.default)(this.last_used_at).format('DD MMM, YYYY HH:mm');
    }

    get expiresAt() {
      if (!this.expires_at) {
        return 'Never';
      }

      return (0, _moment.default)(this.expires_at).format('DD MMM, YYYY HH:mm');
    }

    get createdAt() {
      return (0, _moment.default)(this.created_at).format('DD MMM, YYYY');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user_uuid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "company_uuid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "key", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "secret", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "test_mode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "api", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "browser_origins", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "last_used_at", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "expires_at", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fullName", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "fullName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "environment", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "environment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "lastUsed", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "lastUsed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expiresAt", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "expiresAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype)), _class));
  _exports.default = ApiCredential;
});