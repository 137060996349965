define("@fleetbase/console/controllers/onboard/verify-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OnboardVerifyEmailController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed.not('isReadyToSubmit'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class OnboardVerifyEmailController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "hello", _descriptor, this);

      _initializerDefineProperty(this, "isLoading", _descriptor2, this);

      _initializerDefineProperty(this, "isReadyToSubmit", _descriptor3, this);

      _initializerDefineProperty(this, "stillWaiting", _descriptor4, this);

      _initializerDefineProperty(this, "isNotReadyToSubmit", _descriptor5, this);

      _initializerDefineProperty(this, "code", _descriptor6, this);

      _initializerDefineProperty(this, "queryParams", _descriptor7, this);

      _initializerDefineProperty(this, "fetch", _descriptor8, this);

      _initializerDefineProperty(this, "notifications", _descriptor9, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor10, this);

      _initializerDefineProperty(this, "currentUser", _descriptor11, this);
    }

    validateInput(_ref) {
      let {
        target
      } = _ref;
      const {
        value
      } = target;

      if (value.length > 5) {
        this.isReadyToSubmit = true;
      } else {
        this.isReadyToSubmit = false;
      }
    }

    verifyCode() {
      const session = this.hello;
      const code = this.code;
      this.isLoading = true;
      return this.fetch.post('onboard/verify-email', {
        session,
        code
      }).then(response => {
        if (response.status === 'success') {
          this.notifications.success('Email successfully verified!');
          this.notifications.info('Welcome to Fleetbase!');
          return this.transitionToRoute('console');
        }

        this.isLoading = false;
      }).catch(error => {
        this.notifications.serverError(error);
        this.isLoading = false;
      });
    }

    resendBySms() {
      this.modalsManager.show('modals/verify-by-sms', {
        title: 'Verify Account by Phone',
        acceptButtonText: 'Send',
        phone: this.currentUser.phone,
        confirm: modal => {
          modal.startLoading();
          const phone = modal.getOption('phone');
          return this.fetch.post('onboard/send-verification-sms', {
            phone
          }).then(() => {
            this.notifications.success('Verification code SMS sent!');
          });
        }
      });
    }

    resendEmail() {
      this.modalsManager.show('modals/resend-verification-email', {
        title: 'Resend Verification Code',
        acceptButtonText: 'Send',
        email: this.currentUser.email,
        confirm: modal => {
          modal.startLoading();
          const email = modal.getOption('email');
          return this.fetch.post('onboard/send-verification-email', {
            email
          }).then(() => {
            this.notifications.success('Verification code email sent!');
          });
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hello", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isReadyToSubmit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stillWaiting", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isNotReadyToSubmit", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "code", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['hello'];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "validateInput", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "validateInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "verifyCode", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "verifyCode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resendBySms", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "resendBySms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resendEmail", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "resendEmail"), _class.prototype)), _class));
  _exports.default = OnboardVerifyEmailController;
});