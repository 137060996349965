define("@fleetbase/console/utils/get-weight-units", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getWeightUnits;

  function getWeightUnits() {
    return [{
      name: 'Grams',
      value: 'g'
    }, {
      name: 'Kilograms',
      value: 'kg'
    }, {
      name: 'Grain',
      value: 'gr'
    }, {
      name: 'Dram',
      value: 'dr'
    }, {
      name: 'Ounce',
      value: 'oz'
    }, {
      name: 'Pound',
      value: 'lb'
    }, {
      name: 'Tonne',
      value: 't'
    }];
  }
});