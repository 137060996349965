define("@fleetbase/console/utils/is-video-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isVideoFile;

  function isVideoFile(type) {
    return /video|mp4|mov|wmv|avi|flv/i.test(type);
  }
});