define("@fleetbase/console/helpers/in-string", ["exports", "greenfreight-extension/helpers/in-string"], function (_exports, _inString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _inString.default;
    }
  });
  Object.defineProperty(_exports, "inString", {
    enumerable: true,
    get: function () {
      return _inString.inString;
    }
  });
});