define("@fleetbase/console/services/url-search-params", ["exports", "@fleetbase/console/utils/has-json-structure"], function (_exports, _hasJsonStructure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UrlSearchParamsService = (_dec = Ember._tracked, (_class = class UrlSearchParamsService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "urlParams", _descriptor, this);
    }

    /**
     * Update the URL params
     *
     * @void
     */
    setSearchParams() {
      this.urlParams = new URLSearchParams(window.location.search);
      return this;
    }
    /**
     * Get a param
     *
     * @param {String} key the url param
     * @return mixed
     */


    getParam(key) {
      this.setSearchParams();
      let value = this.urlParams.get(key);

      if ((0, _hasJsonStructure.default)(value)) {
        value = JSON.parse(value);
      }

      return value;
    }
    /**
     * Get a param
     *
     * @param {String} key the url param
     * @return mixed
     */


    get(key) {
      return this.getParam(key);
    }
    /**
     * Determines if a queryParam exists
     *
     * @param {String} key the url param
     * @var {Boolean}
     */


    exists(key) {
      this.setSearchParams();
      return this.urlParams.has(key);
    }
    /**
     * Remove a queryparam
     *
     * @param {String} key the url param
     * @void
     */


    remove(key) {
      this.setSearchParams();
      return this.urlParams.delete(key);
    }
    /**
     * Returns object of all params
     *
     * @return {Array}
     */


    all() {
      this.setSearchParams();
      const all = {};

      for (let key of this.urlParams.keys()) {
        all[key] = this.getParam(key);
      }

      return all;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "urlParams", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UrlSearchParamsService;
});