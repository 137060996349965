define("@fleetbase/console/components/modals/order-ready-assign-driver", ["exports", "storefront/components/modals/order-ready-assign-driver"], function (_exports, _orderReadyAssignDriver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _orderReadyAssignDriver.default;
    }
  });
});