define("@fleetbase/console/serializers/fleet", ["exports", "@fleetbase/console/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FleetSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        service_area: {
          embedded: 'always'
        },
        zone: {
          embedded: 'always'
        },
        drivers: {
          embedded: 'always'
        }
      };
    }

  }

  _exports.default = FleetSerializer;
});