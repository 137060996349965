define("@fleetbase/console/utils/serialize/normalize-relations-within-hash", ["exports", "@fleetbase/console/utils/is-model"], function (_exports, _isModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeNormalizeRelationsWithinHash;

  function serializeNormalizeRelationsWithinHash(hash) {
    let primaryKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'uuid';

    if (typeof hash !== 'object') {
      return hash;
    }

    for (let attr in hash) {
      if (typeof attr === 'string' && attr.includes('_uuid')) {
        // console.log(attr, hash[attr]);
        if (typeof hash[attr] === 'object' && !Ember.isBlank(hash[attr])) {
          // the relation has loaded back into `_uuid` - change this to the proper `uuid` string value and set the relationship
          const relation = hash[attr];
          const id = Ember.get(relation, primaryKey);
          const relationAttr = attr.replace('_uuid', ''); // console.log(`normalizing relation ${relationAttr} from ${attr}`);
          // console.log(relationAttr, relation);
          // console.log(attr, id);

          Ember.setProperties(hash, {
            [relationAttr]: relation,
            [attr]: id
          });
        }
      }
    }

    return hash;
  }
});