define("@fleetbase/console/helpers/format-meters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatMeters(_ref) {
    let [meters] = _ref;
    return `${Math.round(meters / 1000)}km`;
  });

  _exports.default = _default;
});