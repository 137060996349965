define("@fleetbase/console/routes/console/account/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ConsoleAccountProfileRoute extends Ember.Route {}

  _exports.default = ConsoleAccountProfileRoute;
});