define("@fleetbase/console/routes/console/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ConsoleHomeRoute extends Ember.Route {
    beforeModel() {
      return this.transitionTo('console.fleet-ops');
    }

  }

  _exports.default = ConsoleHomeRoute;
});