define("@fleetbase/console/components/modals/order-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          <object id="pdf" class="border-0 w-full shadow-sm h-40rem" data={{@options.data}} type="application/pdf" alt={{@options.order.public_id}}>
              <div class="text-center font-semibold dark:text-gray-100 p-6 flex items-center justify-center">Loading Label...</div>
          </object>
      </div>
  </Ui::Modal>
  */
  {
    "id": "Fmd3r9tP",
    "block": "{\"symbols\":[\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,1],[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[10,\"object\"],[14,1,\"pdf\"],[14,0,\"border-0 w-full shadow-sm h-40rem\"],[15,\"data\",[32,2,[\"data\"]]],[15,\"alt\",[32,2,[\"order\",\"public_id\"]]],[14,4,\"application/pdf\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"text-center font-semibold dark:text-gray-100 p-6 flex items-center justify-center\"],[12],[2,\"Loading Label...\"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/order-label.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});