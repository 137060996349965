define("@fleetbase/console/utils/is-image-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isImageFile;

  function isImageFile(type) {
    return /image|jpg|jpeg|png|gif|webp/i.test(type);
  }
});