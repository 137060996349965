define("@fleetbase/console/utils/make-dataset", ["exports", "@fleetbase/console/utils/group-by", "moment"], function (_exports, _groupBy, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = makeDataset;

  function makeDataset(recordArray) {
    let filter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Boolean;
    let dateProperty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'created_at';
    const filteredData = recordArray.filter(filter);
    const groupedData = (0, _groupBy.default)(filteredData, record => {
      return (0, _moment.default)(new Date(record[dateProperty])).format('MMMM, DD YYYY');
    });
    const dataset = [];

    for (let day in groupedData) {
      dataset.pushObject({
        t: new Date(`${day} 00:00:00`),
        y: groupedData[day].length
      });
    }

    return dataset;
  }
});