define("@fleetbase/console/components/emmi-modals-container/process", ["exports", "ember-modals-manager-internal/components/emmi-modals-container/process"], function (_exports, _process) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _process.default;
    }
  });
});