define("@fleetbase/console/components/widget/storefront-metrics", ["exports", "storefront/components/widget/storefront-metrics"], function (_exports, _storefrontMetrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _storefrontMetrics.default;
    }
  });
});