define("@fleetbase/console/models/certification", ["exports", "greenfreight-extension/models/certification"], function (_exports, _certification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _certification.default;
    }
  });
});