define("@fleetbase/console/components/next/overlay/header", ["exports", "@fleetbase/ui/components/next/overlay/header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _header.default;
    }
  });
});