define("@fleetbase/console/system/modals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const modals = [{
    title: 'Vendor Compliance Lookup',
    component: null,
    placement: ['index.action-menu', 'view.action-menu'],
    description: 'Allow user to perform a compliance lookup on a vendor.',
    service: 'fleet-ops'
  }];
  var _default = modals;
  _exports.default = _default;
});