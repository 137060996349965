define("@fleetbase/console/controllers/auth/login", ["exports", "@fleetbase/console/utils/path-to-route"], function (_exports, _pathToRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthLoginController = (_dec = Ember.inject.controller('auth.forgot-password'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class AuthLoginController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "forgotPasswordController", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "urlSearchParams", _descriptor3, this);

      _initializerDefineProperty(this, "rememberMe", _descriptor4, this);

      _initializerDefineProperty(this, "identity", _descriptor5, this);

      _initializerDefineProperty(this, "password", _descriptor6, this);

      _initializerDefineProperty(this, "isValidating", _descriptor7, this);

      _initializerDefineProperty(this, "isLoading", _descriptor8, this);

      _initializerDefineProperty(this, "isSlowConnection", _descriptor9, this);

      _initializerDefineProperty(this, "timeout", _descriptor10, this);

      _initializerDefineProperty(this, "failedAttempts", _descriptor11, this);
    }

    /**
     * Authenticate the user
     *
     * @void
     */
    async login() {
      // get user credentials
      const {
        email,
        password,
        rememberMe
      } = this; // start loader

      this.set('isLoading', true); // set where to redirect on login

      this.setRedirect();

      try {
        await this.session.authenticate('authenticator:fleetbase', {
          email,
          password
        }, rememberMe);
      } catch (error) {
        this.failedAttempts++;
        return this.failure(error);
      }

      if (this.session.isAuthenticated) {
        this.success();
      }
    }
    /**
     * Transition user to onboarding screen
     */


    transitionToOnboard() {
      return this.transitionToRoute('onboard');
    }
    /**
     * Transition to forgot password screen, if email is set - set it.
     */


    forgotPassword() {
      return this.transitionToRoute('auth.forgot-password').then(() => {
        if (this.email) {
          this.forgotPasswordController.email = this.email;
        }
      });
    }
    /**
     * Sets correct route to send user to after login.
     *
     * @void
     */


    setRedirect() {
      const shift = this.urlSearchParams.get('shift');

      if (shift) {
        this.session.setRedirect((0, _pathToRoute.default)(shift));
      }
    }
    /**
     * Handles the authentication success
     *
     * @void
     */


    success() {
      this.reset('success');
    }
    /**
     * Handles the authentication failure
     *
     * @param {String} error An error message
     * @void
     */


    failure(error) {
      this.notifications.serverError(error);
      this.reset('error');
    }
    /**
     * Handles the request slow connection
     *
     * @void
     */


    slowConnection() {
      this.notifications.error('Experiencing connectivity issues.');
    }
    /**
     * Reset the login form
     *
     * @param {String} type
     * @void
     */


    reset(type) {
      // reset login form state
      this.isLoading = false;
      this.isSlowConnection = false; // reset login form state depending on type of reset

      switch (type) {
        case 'success':
          this.identity = null;
          this.password = null;
          this.isValidating = false;
          break;

        case 'error':
        case 'fail':
          this.password = null;
          break;
      } // clearTimeout(this.timeout);

    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "forgotPasswordController", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "urlSearchParams", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "rememberMe", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "identity", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "password", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isValidating", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isSlowConnection", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "timeout", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "failedAttempts", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "login", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToOnboard", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToOnboard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "forgotPassword", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "forgotPassword"), _class.prototype)), _class));
  _exports.default = AuthLoginController;
});