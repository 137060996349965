define("@fleetbase/console/initializers/inject-core-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    const services = ['fetch', 'session', 'currentUser']; // inject services

    services.forEach(service => {
      application.inject('route', service, `service:${service}`);
      application.inject('controller', service, `service:${service}`);
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});