define("@fleetbase/console/utils/is-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isJson;

  function isJson(str) {
    if (typeof str !== 'string') return false;

    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  }
});