define("@fleetbase/console/components/fetch-select", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="fetch-select {{@wrapperClass}}" {{did-insert (fn this.fetchOptions)}}>
      <Ui::Select ...attributes @fetched={{true}} @options={{this.options}} @placeholder={{this.placeholder}} @optionLabel={{@optionLabel}} @optionValue={{@optionValue}} @onSelect={{@onSelect}} @humanize={{@humanize}} as |option key optionLabel|>
          {{yield option key optionLabel}}
      </Ui::Select>
  </div>
  */
  {
    "id": "WSuVH4xT",
    "block": "{\"symbols\":[\"option\",\"key\",\"optionLabel\",\"@wrapperClass\",\"&attrs\",\"@optionLabel\",\"@optionValue\",\"@onSelect\",\"@humanize\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"fetch-select \",[32,4]]]],[4,[38,1],[[30,[36,0],[[32,0,[\"fetchOptions\"]]],null]],null],[12],[2,\"\\n    \"],[8,\"ui/select\",[[17,5]],[[\"@fetched\",\"@options\",\"@placeholder\",\"@optionLabel\",\"@optionValue\",\"@onSelect\",\"@humanize\"],[true,[32,0,[\"options\"]],[32,0,[\"placeholder\"]],[32,6],[32,7],[32,8],[32,9]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[18,10,[[32,1],[32,2],[32,3]]],[2,\"\\n    \"]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/fetch-select.hbs"
    }
  });

  let FetchSelectComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember.computed('args.placeholder', 'isLoading'), _dec5 = Ember._action, (_class = class FetchSelectComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "options", _descriptor2, this);

      _initializerDefineProperty(this, "isLoading", _descriptor3, this);
    }

    get palceholder() {
      const {
        placeholder
      } = this.args;

      if (placeholder) {
        return placeholder;
      }

      if (this.isLoading) {
        return 'Loading options...';
      }

      return null;
    }

    fetchOptions() {
      const path = this.args.path;

      if (!path) {
        return;
      }

      this.fetch.get(path).then(options => {
        this.options = options;
        this.isLoading = false;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "palceholder", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "palceholder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchOptions", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "fetchOptions"), _class.prototype)), _class));
  _exports.default = FetchSelectComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FetchSelectComponent);
});