define("@fleetbase/console/app", ["exports", "ember-resolver", "ember-load-initializers", "@fleetbase/console/config/environment", "logrocket"], function (_exports, _emberResolver, _emberLoadInitializers, _environment, _logrocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const externalRoutes = {
    console: 'console.home',
    'fleet-ops': 'console.fleet-ops',
    fleetops: 'console.fleet-ops',
    extensions: 'console.extensions',
    billing: 'console.account.billing',
    storefront: 'console.storefront',
    greenfreight: 'console.greenfreight-extension',
    developers: 'console.developers-console',
    iam: 'console.iam'
  };

  class App extends Ember.Application {
    constructor() {
      super(...arguments); // Initialize LogRocket

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);

      _logrocket.default.init('fleetbase-pte-ltd/fleetbase-console');

      this.engines = {
        'developers-console': {
          dependencies: {
            services: ['store', 'session', 'current-user', 'fetch', 'media', 'url-search-params', 'modals-manager', 'notifications', {
              'hostRouter': 'router'
            }, 'theme', 'loader', 'app-cache'],
            externalRoutes
          }
        },
        iam: {
          dependencies: {
            services: ['store', 'session', 'current-user', 'fetch', 'media', 'url-search-params', 'modals-manager', 'notifications', {
              'hostRouter': 'router'
            }, 'theme', 'loader', 'app-cache'],
            externalRoutes
          }
        },
        'fleet-ops': {
          dependencies: {
            services: ['store', 'session', 'current-user', 'fetch', 'media', 'url-search-params', 'modals-manager', 'notifications', {
              'hostRouter': 'router'
            }, 'crud', 'loader', 'service-areas', 'app-cache'],
            externalRoutes
          }
        },
        storefront: {
          dependencies: {
            services: ['store', 'session', 'current-user', 'fetch', 'media', 'url-search-params', 'modals-manager', 'notifications', {
              'hostRouter': 'router'
            }, 'crud', 'loader', 'theme', 'app-cache'],
            externalRoutes
          }
        },
        'greenfreight-extension': {
          dependencies: {
            services: ['store', 'session', 'current-user', 'fetch', 'media', 'url-search-params', 'modals-manager', 'notifications', {
              'hostRouter': 'router'
            }, 'crud', 'loader'],
            externalRoutes
          }
        }
      };
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});