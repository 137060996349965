define("@fleetbase/console/components/next/map-container/toolbar/zones-panel", ["exports", "@fleetbase/ui/components/next/map-container/toolbar/zones-panel"], function (_exports, _zonesPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _zonesPanel.default;
    }
  });
});