define("@fleetbase/console/utils/refresh-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = refreshRoute;

  function refreshRoute(controller) {
    return controller.target.targetState.router.refresh();
  }
});