define("@fleetbase/console/utils/is-function", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isFunction;

  function isFunction() {
    return true;
  }
});