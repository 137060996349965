define("@fleetbase/console/templates/events", ["exports", "developers-console/templates/events"], function (_exports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _events.default;
    }
  });
});