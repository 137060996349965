define("@fleetbase/console/helpers/date-format", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function dateFormat(_ref) {
    let [date, fmt] = _ref;
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _dateFns.format)(date, fmt, options);
  });

  _exports.default = _default;
});