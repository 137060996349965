define("@fleetbase/console/components/modals/order-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          <div class="px-3 mb-4">
              <div class="flex justify-between items-start">
                  <div class="flex-1 flex items-center">
                      <label class="dark:text-gray-100 mr-3">Schedule</label>
                      
                      <DateTimeInput class="form-input" @value={{@options.order.scheduled_at}} @minDate={{moment-format (now) "YYYY-MM-DD" }}
                          @minTime={{moment-format (now) "HH:mm" }} @onUpdate={{fn (mut @options.order.scheduled_at)}} />
                  </div>
                  <div class="flex justify-end">
                      <Ui::Button @type="danger" @text="Unschedule" @onClick={{fn @options.unschedule @options.order}} />
                  </div>
              </div>
          </div>
  
          <Order::ScheduleCard class="border border-night-905" @itemClass="bg-green-600" @contentClass="bg-transparent-i py-3" @titleClass="hidden-i" @order={{@options.order}} />
      </div>
  </Ui::Modal>
  */
  {
    "id": "wqDgHVlY",
    "block": "{\"symbols\":[\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,1],[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"px-3 mb-4\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"flex justify-between items-start\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"flex-1 flex items-center\"],[12],[2,\"\\n                    \"],[10,\"label\"],[14,0,\"dark:text-gray-100 mr-3\"],[12],[2,\"Schedule\"],[13],[2,\"\\n                    \\n                    \"],[8,\"date-time-input\",[[24,0,\"form-input\"]],[[\"@value\",\"@minDate\",\"@minTime\",\"@onUpdate\"],[[32,2,[\"order\",\"scheduled_at\"]],[30,[36,1],[[30,[36,0],null,null],\"YYYY-MM-DD\"],null],[30,[36,1],[[30,[36,0],null,null],\"HH:mm\"],null],[30,[36,3],[[30,[36,2],[[32,2,[\"order\",\"scheduled_at\"]]],null]],null]]],null],[2,\"\\n                \"],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"flex justify-end\"],[12],[2,\"\\n                    \"],[8,\"ui/button\",[],[[\"@type\",\"@text\",\"@onClick\"],[\"danger\",\"Unschedule\",[30,[36,3],[[32,2,[\"unschedule\"]],[32,2,[\"order\"]]],null]]],null],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[8,\"order/schedule-card\",[[24,0,\"border border-night-905\"]],[[\"@itemClass\",\"@contentClass\",\"@titleClass\",\"@order\"],[\"bg-green-600\",\"bg-transparent-i py-3\",\"hidden-i\",[32,2,[\"order\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"now\",\"moment-format\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/order-event.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});