define("@fleetbase/console/helpers/format-currency", ["exports", "@fleetbase/console/utils/format-currency"], function (_exports, _formatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatCurrency(_ref) {
    let [amount = 0, currencyCode = 'USD'] = _ref;
    return (0, _formatCurrency.default)(amount, currencyCode);
  });

  _exports.default = _default;
});