define("@fleetbase/console/validations/api-credential", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @validations api-credential */
  var _default = {
    name: [(0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});