define("@fleetbase/console/utils/serialize/normalize-polymorphic-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeNormalizePolymorphicType;

  const lowercase = string => string.toLowerCase();

  function serializeNormalizePolymorphicType(polymorphicTypeValue) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    // encode first
    const encodedPolymorphicTypeValue = encodeURI(polymorphicTypeValue); // if encoded is invalid send the default value or the original value back

    if (!encodedPolymorphicTypeValue.includes('%5C')) {
      return defaultValue || polymorphicTypeValue;
    } // from fleetbase api should be something like Fleetbase\Models\Model
    // change value to ember-data polymorphic type


    return lowercase(encodedPolymorphicTypeValue.split('%5C').reverse()[0]);
  }
});