define("@fleetbase/console/components/ember-dragula", ["exports", "@zestia/ember-dragula/components/ember-dragula"], function (_exports, _emberDragula) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emberDragula.default;
    }
  });
});