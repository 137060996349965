define("@fleetbase/console/utils/get-model-name", ["exports", "@fleetbase/console/utils/is-model"], function (_exports, _isModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getModelName;

  function getModelName(model) {
    if ((0, _isModel.default)(model)) {
      return Ember.getWithDefault(model, '_internalModel.modelName', 'resource');
    }

    return 'resource';
  }
});