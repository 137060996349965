define("@fleetbase/console/controllers/onboard/activate-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OnboardActivateServicesController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed('selectedInterval'), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class OnboardActivateServicesController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "stripev3", _descriptor3, this);

      _initializerDefineProperty(this, "currentUser", _descriptor4, this);

      _initializerDefineProperty(this, "isLoading", _descriptor5, this);

      _initializerDefineProperty(this, "isValid", _descriptor6, this);

      _initializerDefineProperty(this, "stripeElement", _descriptor7, this);

      _initializerDefineProperty(this, "_price", _descriptor8, this);

      _initializerDefineProperty(this, "selectedInterval", _descriptor9, this);

      _defineProperty(this, "stripeElementOptions", {
        style: {
          base: {
            color: '#ffffff',
            lineHeight: '1.5',
            iconColor: '#9fa6b2',
            fontSize: '16px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: '#9fa6b2'
            }
          },
          invalid: {
            iconColor: '#F87171',
            color: '#F87171'
          }
        }
      });
    }

    get priceOptions() {
      // sort price options by cheapest tier first
      return this.model.filter(price => price.billing_scheme === 'tiered').filter(price => price.recurring.interval === this.selectedInterval).sort((a, b) => {
        return a.tiers.firstObject.flat_amount - b.tiers.firstObject.flat_amount;
      });
    }

    get price() {
      if (this._price) {
        return this._price;
      }

      return this.priceOptions.firstObject;
    }

    set price(price) {
      this._price = price;
    }

    invalidateInput() {
      this.isValid = false;
    }

    validateInput(event, stripeElement) {
      if (stripeElement.error) {
        this.isValid = false;
      }
    }

    setStripeElement(stripeElement) {
      this.stripeElement = stripeElement;
      this.isValid = true;
    }

    setPriceOption(price) {
      this.price = price;
    }

    activateSubscription() {
      const stripeElement = this.stripeElement;
      const priceId = this.price.id;
      this.isLoading = true;
      return this.stripev3.createPaymentMethod({
        type: 'card',
        card: stripeElement
      }).then(result => {
        if (result.error) {
          this.isLoading = false;
          this.notifications.error(result.error.message);
          return;
        }

        return this.fetch.post('billing/create-subscription', {
          paymentMethodId: result.paymentMethod.id,
          priceId
        }).then(response => {
          if (response.status === 'success') {
            this.notifications.success('Subscription started, services activated.');
            window.tap('create', '23067-beb221', {
              integration: 'stripe'
            });
            window.tap('conversion', response.charge_id, response.amount_paid, {
              customer_id: response.customer_id
            });
            return this.transitionToRoute('console.home');
          }

          this.isLoading = false;
        }).catch(error => {
          this.notifications.serverError(error);
          this.isLoading = false;
        });
      }).catch(error => {
        this.isLoading = false;
        this.notifications.error(error.message);
      });
    }

    continueWithTrial() {
      this.fetch.post('billing/trial').then(() => {
        window.tap('create', '23067-beb221', {
          integration: 'stripe'
        });
        return this.transitionToRoute('console.home');
      }).catch(error => {
        this.notifications.serverError(error);
        return this.transitionToRoute('console.home');
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stripev3", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "stripeElement", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_price", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedInterval", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'month';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "priceOptions", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "priceOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "invalidateInput", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "invalidateInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateInput", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "validateInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setStripeElement", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setStripeElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPriceOption", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setPriceOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "activateSubscription", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "activateSubscription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "continueWithTrial", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "continueWithTrial"), _class.prototype)), _class));
  _exports.default = OnboardActivateServicesController;
});