define("@fleetbase/console/transforms/multi-polygon", ["exports", "@ember-data/serializer/transform", "terraformer"], function (_exports, _transform, _terraformer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MultiPolygonTransform extends _transform.default {
    deserialize(serialized) {
      if (Ember.isNone(serialized)) {
        return serialized;
      }

      return new _terraformer.default.MultiPolygon(serialized);
    }

    serialize(deserialized) {
      if (Ember.isNone(deserialized)) {
        return deserialized;
      }

      return new _terraformer.default.MultiPolygon(deserialized);
    }

  }

  _exports.default = MultiPolygonTransform;
});