define("@fleetbase/console/services/theme", ["exports", "@fleetbase/console/utils/close-sidebar"], function (_exports, _closeSidebar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ThemeService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ThemeService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _defineProperty(this, "currentTheme", 'dark');
    }

    /**
     * The current active theme, uses the system preffered mode to set default
     * refers to the theme in headData service
     *
     * @var {String}
     */
    get activeTheme() {
      const userSetTheme = this.currentUser.getOption(`theme`);

      if (userSetTheme) {
        return userSetTheme;
      }

      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark';
      } // default to dark theme


      return 'dark';
    }
    /**
     * Set the activeTheme in the headData service
     */


    set activeTheme(theme) {
      document.body.dataset.theme = theme;
    }
    /**
     * Current theme, defaults to light, active theme represents the theme set by user OS
     *
     * @var {String}
     */


    /**
     * The current route name as style class
     *
     * @var {String}
     */
    get routeClassName() {
      return Ember.String.dasherize(typeof this.router.currentRouteName === 'string' ? this.router.currentRouteName.replace(/\./g, ' ') : 'fleetbase-console');
    }
    /**
     * The current route
     *
     * @var {Route}
     */


    get currentRoute() {
      return Ember.getOwner(this).lookup(`route:${this.router.currentRouteName}`);
    }
    /**
     * The current route
     *
     * @var {Route}
     */


    get currentRouteBodyClasses() {
      if (this.currentRoute && this.currentRoute.bodyClassNames && Ember.isArray(this.currentRoute.bodyClassNames)) {
        return this.currentRoute.bodyClassNames;
      }

      return [];
    }
    /**
     * Hook for handling when route does change
     *
     * @void
     */


    routeDidChange() {
      this.setRoutebodyClassNames(this.currentRouteBodyClasses);
    }
    /**
     * Hook for handling when route does change
     *
     * @void
     */


    routeWillChange() {
      (0, _closeSidebar.default)();
      this.removeRoutebodyClassNames(this.currentRouteBodyClasses);
    }
    /**
     * Initialize theme configurations
     *
     * @void
     */


    initialize() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.setTheme(this.activeTheme);
      this.setEnvironment();
      this.resetScroll();
      this.setRoutebodyClassNames(options.bodyClassNames && Ember.isArray(options.bodyClassNames) ? options.bodyClassNames : []); // on every subsequent transition set the body class

      this.router.on('routeDidChange', this.routeDidChange.bind(this)); // remove route class as exiting

      this.router.on('routeWillChange', this.routeWillChange.bind(this)); // remove console-loader

      const consoleLoader = document.getElementById(`console-loader`);

      if (consoleLoader) {
        consoleLoader.remove();
      }
    }
    /**
     * Resets window scroll
     *
     * @void
     */


    resetScroll() {
      window.scrollTo(0, 0);
    }
    /**
     * Appends the current route name to body
     *
     * @void
     */


    setRoutebodyClassNames() {
      let classes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      document.body.classList.add(...[this.routeClassName, `${this.currentTheme}-theme`, ...classes]);
    }
    /**
     * Remove thes current route name from body
     *
     * @void
     */


    removeRoutebodyClassNames() {
      let classes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      document.body.classList.remove(...[this.routeClassName, `${this.currentTheme}-theme`, ...classes]);
    }
    /**
     * Toggle the activeTheme between light and dark, and returns the toggled them
     *
     * @return string
     */


    toggleTheme() {
      const nextTheme = this.currentTheme === 'light' ? 'dark' : 'light';
      this.setTheme(nextTheme);
      return nextTheme;
    }
    /**
     * Set the theme to the headData
     *
     * @void
     */


    setTheme() {
      let theme = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'light';
      document.body.classList.remove(`${this.currentTheme}-theme`);
      document.body.classList.add(`${theme}-theme`);
      this.currentUser.setOption(`theme`, theme);
      this.setProperties({
        activeTheme: theme,
        currentTheme: theme
      });
    }
    /**
     * Set the theme to the headData
     *
     * @void
     */


    setEnvironment() {
      const isSandbox = this.currentUser.getOption('sandbox') || false;

      if (isSandbox) {
        document.body.classList.add('sandbox-console');
      } else {
        document.body.classList.remove('sandbox-console');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ThemeService;
});