define("@fleetbase/console/templates/wizard", ["exports", "greenfreight-extension/templates/wizard"], function (_exports, _wizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _wizard.default;
    }
  });
});