define("@fleetbase/console/components/modals/rename-api-key-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}} as |options|>
      <div class="modal-body-container">
          <div class="input-group">
              <label>
                  Name
              </label>
              <Input @value={{options.apiKey.name}} type="text" class="w-full form-input" placeholder="Enter a nickname or environment name for API Key" />
          </div>
      </div>
  </Ui::Modal>
  */
  {
    "id": "7/RJkBK3",
    "block": "{\"symbols\":[\"options\",\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,2],[32,3],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n            \"],[10,\"label\"],[12],[2,\"\\n                Name\\n            \"],[13],[2,\"\\n            \"],[8,\"input\",[[24,0,\"w-full form-input\"],[24,\"placeholder\",\"Enter a nickname or environment name for API Key\"],[24,4,\"text\"]],[[\"@value\"],[[32,1,[\"apiKey\",\"name\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/rename-api-key-form.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});