define("@fleetbase/console/components/split-grid", ["exports", "@glimmer/component", "split.js"], function (_exports, _component, _split) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="w-full h-full">
      <div class="split-grid" {{did-insert (fn this.createSplitGrid)}} ...attributes>{{yield}}</div>
  </div>
  
  */
  {
    "id": "7IwGotR/",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"w-full h-full\"],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"split-grid\"],[17,1],[4,[38,1],[[30,[36,0],[[32,0,[\"createSplitGrid\"]]],null]],null],[12],[18,2,null],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/split-grid.hbs"
    }
  });

  let SplitGridComponent = (_dec = Ember._action, (_class = class SplitGridComponent extends _component.default {
    createSplitGrid(element) {
      const panes = Array.from(element.children);
      const sizes = panes.map(pane => pane.dataset.size || 100 / panes.length);
      (0, _split.default)(panes, {
        sizes: sizes,
        minSize: this.args.minSize || 200,
        direction: this.args.direction || 'vertical',
        cursor: this.args.cursor || 'row-resize',
        onDrag: this.args.onDrag,
        onDragStart: this.args.onDragStart,
        onDragEnd: this.args.onDragEnd
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "createSplitGrid", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "createSplitGrid"), _class.prototype)), _class));
  _exports.default = SplitGridComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SplitGridComponent);
});