define("@fleetbase/console/components/widget/iam-resources-count", ["exports", "iam/components/widget/iam-resources-count"], function (_exports, _iamResourcesCount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _iamResourcesCount.default;
    }
  });
});