define("@fleetbase/console/utils/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupBy;

  function groupBy(arr, key) {
    let grouped = {};

    let _key;

    for (let i = 0; i < arr.length; i++) {
      const item = arr.objectAt(i);

      if (typeof key === 'string') {
        _key = Ember.get(item, key);
      }

      if (typeof key === 'function') {
        _key = key(item, i);
      }

      if (!grouped[_key]) {
        grouped[_key] = [];
      }

      grouped[_key].pushObject(item);
    }

    return grouped;
  }
});