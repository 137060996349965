define("@fleetbase/console/helpers/abbreviate-interval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function abbreviateInterval(_ref) {
    let [interval] = _ref;

    if (interval.startsWith('ye')) {
      return 'yr';
    } else if (interval.startsWith('mo')) {
      return 'mo';
    }
  });

  _exports.default = _default;
});