define("@fleetbase/console/utils/array-range", ["exports", "@fleetbase/ui/utils/array-range"], function (_exports, _arrayRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _arrayRange.default;
    }
  });
});