define("@fleetbase/console/helpers/waypoint-label", ["exports", "@fleetbase/console/utils/waypoint-label"], function (_exports, _waypointLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function waypointLabel(_ref) {
    let [index] = _ref;
    return (0, _waypointLabel.default)(index);
  });

  _exports.default = _default;
});