define("@fleetbase/console/components/key-input", ["exports", "@glimmer/component", "ember-uuid"], function (_exports, _component, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Input class="w-full form-input {{@inputClass}}" @id={{this.id}} @type={{or @type "text"}} @value={{this.value}} @placeholder={{or @placeholder @name}} @required={{@required}} @disabled={{@disabled}} {{on "keyup" (fn this.formatKey)}} {{on "blur" (fn this.blur)}} ...attributes />
  */
  {
    "id": "3wC9fPgf",
    "block": "{\"symbols\":[\"@inputClass\",\"@type\",\"@name\",\"@placeholder\",\"@required\",\"@disabled\",\"&attrs\"],\"statements\":[[8,\"input\",[[16,0,[31,[\"w-full form-input \",[32,1]]]],[17,7],[4,[38,2],[\"keyup\",[30,[36,1],[[32,0,[\"formatKey\"]]],null]],null],[4,[38,2],[\"blur\",[30,[36,1],[[32,0,[\"blur\"]]],null]],null]],[[\"@id\",\"@type\",\"@value\",\"@placeholder\",\"@required\",\"@disabled\"],[[32,0,[\"id\"]],[30,[36,0],[[32,2],\"text\"],null],[32,0,[\"value\"]],[30,[36,0],[[32,4],[32,3]],null],[32,5],[32,6]]],null]],\"hasEval\":false,\"upvars\":[\"or\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/key-input.hbs"
    }
  });

  let KeyInputComponent = (_dec = Ember._tracked, _dec2 = Ember.computed('args.value', '_value'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class KeyInputComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "_value", _descriptor, this);
    }

    get id() {
      return (0, _emberUuid.v4)();
    }

    get value() {
      if (this._value) {
        return this._value;
      }

      return this.args.value;
    }

    set value(value) {
      this._value = Ember.String.underscore(value);
    }

    setValue(value) {
      let callbackName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'onChange';
      this.value = value;

      if (typeof this.args[callbackName] === 'function') {
        this.args[callbackName](this.value);
      }
    }

    formatKey(_ref2) {
      let {
        target
      } = _ref2;
      const {
        value
      } = target;
      this.setValue(value);
    }

    blur(_ref3) {
      let {
        target
      } = _ref3;
      const {
        value
      } = target;
      this.setValue(value, 'onBlur');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "value", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setValue", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formatKey", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "formatKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "blur", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "blur"), _class.prototype)), _class));
  _exports.default = KeyInputComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, KeyInputComponent);
});