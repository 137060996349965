define("@fleetbase/console/utils/words", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = words;

  function words() {
    let string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return Ember.String.dasherize(string).replace(/-/g, ' ');
  }
});