define("@fleetbase/console/utils/numbers-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = numbersOnly;

  function numbersOnly(string) {
    let keepDecimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (keepDecimals === true) {
      return string.replace(/[^0-9.]+/g, '');
    }

    return string.replace(/\D+/g, '');
  }
});