define("@fleetbase/console/templates/console/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M6ZVyUu/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-wormhole\",[],[[\"@to\"],[\"sidebar-menu-items\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"next/sidebar/panel\",[],[[\"@open\",\"@title\"],[true,\"Account\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"next/sidebar/item\",[],[[\"@route\",\"@icon\"],[\"console.account.settings\",\"building\"]],[[\"default\"],[{\"statements\":[[2,\"Organization\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"next/sidebar/item\",[],[[\"@route\",\"@icon\"],[\"console.account.profile\",\"user\"]],[[\"default\"],[{\"statements\":[[2,\"Profile\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"next/sidebar/item\",[],[[\"@route\",\"@icon\"],[\"console.account.billing\",\"credit-card\"]],[[\"default\"],[{\"statements\":[[2,\"Billing\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"next/view-section-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ember-scrollable\",[],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/templates/console/account.hbs"
    }
  });

  _exports.default = _default;
});