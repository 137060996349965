define("@fleetbase/console/controllers/management/fleets/index/details", ["exports", "fleet-ops/controllers/management/fleets/index/details"], function (_exports, _details) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _details.default;
    }
  });
});