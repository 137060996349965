define("@fleetbase/console/services/socket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SocketService extends Ember.Service {}

  _exports.default = SocketService;
});