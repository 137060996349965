define("@fleetbase/console/components/leaflet-draw-control", ["exports", "leaflet-draw", "ember-leaflet/components/base-layer"], function (_exports, _leafletDraw, _baseLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-get */

  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */

  /* eslint-disable no-undef */
  const L = window.L;

  var _default = _baseLayer.default.extend({
    enableDeleting: true,
    // Default value
    enableEditing: true,
    // Default value
    showDrawingLayer: true,
    // Default value
    leafletEvents: [L.Draw.Event.CREATED, L.Draw.Event.EDITED, L.Draw.Event.EDITMOVE, L.Draw.Event.EDITRESIZE, L.Draw.Event.EDITSTART, L.Draw.Event.EDITSTOP, L.Draw.Event.EDITVERTEX, L.Draw.Event.DELETED, L.Draw.Event.DELETESTART, L.Draw.Event.DELETESTOP, L.Draw.Event.DRAWSTART, L.Draw.Event.DRAWSTOP, L.Draw.Event.DRAWVERTEX],
    leafletOptions: ['draw', 'edit', 'enableEditing', 'position', 'showDrawingLayer'],
    usedLeafletEvents: Ember.computed('leafletEvents', function () {
      return Ember.get(this, 'leafletEvents').filter(eventName => {
        eventName = Ember.String.camelize(eventName.replace(':', ' '));
        const methodName = '_' + eventName;
        const actionName = 'on' + Ember.String.classify(eventName);
        return Ember.get(this, methodName) !== undefined || Ember.get(this, actionName) !== undefined;
      });
    }),

    addToContainer() {
      if (this._layer) {
        Ember.get(this, 'parentComponent')._layer.addLayer(this._layer);
      }
    },

    createLayer() {
      let drawingLayerGroup;

      if (Ember.get(this, 'showDrawingLayer')) {
        drawingLayerGroup = new this.L.FeatureGroup();
        const map = Ember.get(this, 'parentComponent._layer');
        drawingLayerGroup.addTo(map);

        if (typeof this.onDrawFeatureGroupCreated === 'function') {
          this.onDrawFeatureGroupCreated(drawingLayerGroup, map);
        }
      }

      return drawingLayerGroup;
    },

    didCreateLayer() {
      const map = Ember.get(this, 'parentComponent._layer');

      if (map) {
        let options = Ember.getProperties(this, 'position', 'draw', 'edit');

        if (!options.position) {
          options.position = 'topleft';
        }

        if (this._layer) {
          options.edit = Ember.assign({
            featureGroup: this._layer
          }, options.edit);

          if (!Ember.get(this, 'enableEditing') && !options.edit.edit) {
            options.edit.edit = false;
          }

          if (!Ember.get(this, 'enableDeleting') && !options.edit.remove) {
            options.edit.remove = false;
          } // Extend the default draw object with options overrides


          options.draw = Ember.assign({}, this.L.drawLocal.draw, options.draw); // create draw control

          const drawControl = new this.L.Control.Draw(options); // trigger action/event draw control created

          if (typeof this.onDrawControlCreated === 'function') {
            this.onDrawControlCreated(drawControl, map);
          } // Add the draw control to the map


          map.addControl(drawControl); // trigger action/event draw control added to map

          if (typeof this.onDrawControlAddedToMap === 'function') {
            this.onDrawControlAddedToMap(drawControl, map);
          } // If showDrawingLayer, add new layer to the layerGroup


          if (Ember.get(this, 'showDrawingLayer')) {
            map.on(this.L.Draw.Event.CREATED, e => {
              const layer = e.layer;

              this._layer.addLayer(layer);
            });
          }
        }
      }
    },

    _addEventListeners() {
      this._eventHandlers = {};
      Ember.get(this, 'usedLeafletEvents').forEach(eventName => {
        const originalEventName = eventName;
        const map = Ember.get(this, 'parentComponent._layer'); // Cleanup the Leaflet Draw event names that have colons, ex:'draw:created'

        eventName = Ember.String.camelize(eventName.replace(':', ' '));
        const actionName = 'on' + Ember.String.classify(eventName);
        const methodName = '_' + eventName; // Create an event handler that runs the function inside an event loop.

        this._eventHandlers[originalEventName] = function (e) {
          Ember.run(() => {
            // Try to invoke/send an action for this event
            this.invokeAction(actionName, e, this._layer, map); // Allow classes to add custom logic on events as well

            if (typeof this[methodName] === 'function') {
              Ember.run(this, this[methodName], e, this._layer, map);
            }
          });
        }; // The events for Leaflet Draw are on the map object, not the layer


        map.addEventListener(originalEventName, this._eventHandlers[originalEventName], this);
      });
    },

    _removeEventListeners() {
      if (this._eventHandlers) {
        Ember.get(this, 'usedLeafletEvents').forEach(eventName => {
          const map = Ember.get(this, 'parentComponent._layer'); // The events for Leaflet Draw are on the map object, not the layer

          map.removeEventListener(eventName, this._eventHandlers[eventName], this);
          delete this._eventHandlers[eventName];
        });
      }
    }

  });

  _exports.default = _default;
});