define("@fleetbase/console/utils/is-not-model", ["exports", "@fleetbase/console/utils/is-model"], function (_exports, _isModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isNotModel;

  function isNotModel(model) {
    return !(0, _isModel.default)(model);
  }
});