define("@fleetbase/console/helpers/has-met-dependency", ["exports", "greenfreight-extension/helpers/has-met-dependency"], function (_exports, _hasMetDependency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _hasMetDependency.default;
    }
  });
  Object.defineProperty(_exports, "hasMetDependency", {
    enumerable: true,
    get: function () {
      return _hasMetDependency.hasMetDependency;
    }
  });
});