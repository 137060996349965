define("@fleetbase/console/helpers/ember-leaflet-assign-to", ["exports", "ember-leaflet/helpers/ember-leaflet-assign-to"], function (_exports, _emberLeafletAssignTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emberLeafletAssignTo.default;
    }
  });
  Object.defineProperty(_exports, "emberLeafletAssignTo", {
    enumerable: true,
    get: function () {
      return _emberLeafletAssignTo.emberLeafletAssignTo;
    }
  });
});