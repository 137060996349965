define("@fleetbase/console/utils/set-column-filter-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setColumnFilterOptions;

  function setColumnFilterOptions() {
    return true;
  }
});