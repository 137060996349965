define("@fleetbase/console/serializers/company", ["exports", "@fleetbase/console/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CompanySerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        logo: {
          embedded: 'always'
        },
        backdrop: {
          embedded: 'always'
        }
      };
    }

  }

  _exports.default = CompanySerializer;
});