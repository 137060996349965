define("@fleetbase/console/helpers/is-active-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(_ref) {
      let [route] = _ref;
      const owner = Ember.getOwner(this);
      const router = owner.lookup('router:main');
      const currentRouteName = router.currentRouteName;

      const contains = (haystack, needle) => haystack.includes(needle);

      return contains(currentRouteName, route);
    }

  });

  _exports.default = _default;
});