define("@fleetbase/console/utils/reverse-point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = reversePoint;

  function reversePoint() {
    return true;
  }
});