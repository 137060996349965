define("@fleetbase/console/components/file-icon", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="file-icon file-icon-{{this.fileExtension}}">
      <FaIcon @icon={{this.fileIcon}} />
      <span class="file-extension truncate">
          {{this.fileExtension}}
      </span>
      <div>
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "5/nGO7NZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"file-icon file-icon-\",[32,0,[\"fileExtension\"]]]]],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\"],[[32,0,[\"fileIcon\"]]]],null],[2,\"\\n    \"],[10,\"span\"],[14,0,\"file-extension truncate\"],[12],[2,\"\\n        \"],[1,[32,0,[\"fileExtension\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/file-icon.hbs"
    }
  });

  let FileIconComponent = (_dec = Ember.computed('args.file.extension', 'file.extension'), _dec2 = Ember.computed('fileExtension'), (_class = class FileIconComponent extends _component.default {
    /**
     * Get the easy to read file extension
     *
     * @string
     */
    get fileExtension() {
      // eslint-disable-next-line ember/no-get
      const extension = Ember.get(this, 'args.file.extension');
      const extensionMap = {
        'application/vnd.ms-excel': 'xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
        'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
        'vnd.ms-excel': 'xls',
        'text/csv': 'csv',
        'text/tsv': 'tsv',
        xlsx: 'xls',
        xls: 'xls',
        xlsb: 'xls',
        xlsm: 'xls',
        docx: 'doc',
        docm: 'doc'
      };
      return Ember.get(extensionMap, extension) || 'xls';
    }
    /**
     * Get the easy to read file extension
     *
     * @string
     */


    get fileIcon() {
      // eslint-disable-next-line ember/no-get
      const extension = this.fileExtension;
      const extensionMap = {
        xlsx: 'file-excel',
        xls: 'file-excel',
        xlsb: 'file-excel',
        xlsm: 'file-excel',
        csv: 'file-spreadsheet',
        tsv: 'file-spreadsheet',
        docx: 'file-word',
        docm: 'file-word',
        pdf: 'file-pdf',
        ppt: 'file-powerpoint',
        pptx: 'file-powerpoint'
      };
      return Ember.get(extensionMap, extension) || 'file-alt';
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "fileExtension", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "fileExtension"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fileIcon", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fileIcon"), _class.prototype)), _class));
  _exports.default = FileIconComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FileIconComponent);
});