define("@fleetbase/console/helpers/frontend-url", ["exports", "@fleetbase/console/utils/frontend-url"], function (_exports, _frontendUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function frontendUrl(_ref) {
    let [path = '', queryParams = {}] = _ref;
    return (0, _frontendUrl.default)(path, queryParams);
  });

  _exports.default = _default;
});