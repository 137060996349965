define("@fleetbase/console/helpers/is-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function isObject(_ref) {
    let [param] = _ref;
    return typeof param === 'object';
  });

  _exports.default = _default;
});