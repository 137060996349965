define("@fleetbase/console/utils/close-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = closeSidebar;

  function closeSidebar() {
    var _window, _window$document, _sidebarNode$classLis;

    const sidebarNode = (_window = window) === null || _window === void 0 ? void 0 : (_window$document = _window.document) === null || _window$document === void 0 ? void 0 : _window$document.querySelector('nav.next-sidebar');

    if (sidebarNode !== null && sidebarNode !== void 0 && (_sidebarNode$classLis = sidebarNode.classList) !== null && _sidebarNode$classLis !== void 0 && _sidebarNode$classLis.contains('is-open')) {
      var _sidebarNode$classLis2;

      sidebarNode === null || sidebarNode === void 0 ? void 0 : (_sidebarNode$classLis2 = sidebarNode.classList) === null || _sidebarNode$classLis2 === void 0 ? void 0 : _sidebarNode$classLis2.remove('is-open');
    }
  }
});