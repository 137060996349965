define("@fleetbase/console/utils/is-not-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isNotEmpty;

  function isNotEmpty() {
    return !Ember.isEmpty(...arguments);
  }
});