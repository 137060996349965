define("@fleetbase/console/utils/find-closest-waypoint", ["exports", "@fleetbase/console/utils/haversine"], function (_exports, _haversine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = findClosestWaypoint;

  function findClosestWaypoint(latitude, longitude) {
    let waypoints = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let distances = [];

    for (let i = 0; i < waypoints.length; i++) {
      let waypoint = waypoints.objectAt(i);
      let distance = (0, _haversine.default)({
        latitude,
        longitude
      }, waypoint.place.get('latitudelongitude'));
      distances.pushObject({
        distance,
        waypoint
      });
    }

    distances = distances.sortBy('distance');
    return Ember.get(distances, 'firstObject.waypoint');
  }
});