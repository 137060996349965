define("@fleetbase/console/routes/settings/notifications", ["exports", "storefront/routes/settings/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _notifications.default;
    }
  });
});