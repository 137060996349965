define("@fleetbase/console/components/widget/fleet-ops-metrics", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WidgetFleetOpsMetricsComponent extends _component.default {}

  _exports.default = WidgetFleetOpsMetricsComponent;
});