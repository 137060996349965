define("@fleetbase/console/models/driver", ["exports", "@ember-data/model", "@fleetbase/console/utils/is-relation-missing", "@fleetbase/console/utils/is-valid-coordinates", "@fleetbase/console/utils/is-latitude", "@fleetbase/console/utils/is-longitude", "date-fns", "@fleetbase/console/utils/auto-serialize"], function (_exports, _model, _isRelationMissing, _isValidCoordinates, _isLatitude, _isLongitude, _dateFns, _autoSerialize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Driver = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.belongsTo)('user', {
    async: true
  }), _dec14 = (0, _model.hasMany)('fleet', {
    async: true
  }), _dec15 = (0, _model.hasMany)('order', {
    async: true
  }), _dec16 = (0, _model.belongsTo)('vehicle', {
    async: true
  }), _dec17 = (0, _model.belongsTo)('file', {
    async: true
  }), _dec18 = (0, _model.belongsTo)('order', {
    async: true
  }), _dec19 = (0, _model.belongsTo)('vendor', {
    async: true
  }), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('string'), _dec28 = (0, _model.attr)('string'), _dec29 = (0, _model.attr)('point'), _dec30 = (0, _model.attr)('string'), _dec31 = (0, _model.attr)('string'), _dec32 = (0, _model.attr)('string'), _dec33 = (0, _model.attr)('string'), _dec34 = (0, _model.attr)('boolean'), _dec35 = (0, _model.attr)('date'), _dec36 = (0, _model.attr)('date'), _dec37 = (0, _model.attr)('date'), _dec38 = Ember.computed('updated_at'), _dec39 = Ember.computed('updated_at'), _dec40 = Ember.computed('updated_at'), _dec41 = Ember.computed('created_at'), _dec42 = Ember.computed('created_at'), _dec43 = Ember.computed('created_at'), _dec44 = Ember.computed('location'), _dec45 = Ember.computed('location'), _dec46 = Ember.computed('latitude', 'longitude'), _dec47 = Ember.computed('latitude', 'longitude'), _dec48 = Ember.computed('latitude', 'longitude'), _dec49 = Ember.computed('coordinates'), _dec50 = Ember.computed.not('hasValidCoordinates'), _dec51 = Ember.computed('jobs.@each.status'), (_class = class Driver extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "uuid", _descriptor, this);

      _initializerDefineProperty(this, "public_id", _descriptor2, this);

      _initializerDefineProperty(this, "company_uuid", _descriptor3, this);

      _initializerDefineProperty(this, "user_uuid", _descriptor4, this);

      _initializerDefineProperty(this, "vehicle_uuid", _descriptor5, this);

      _initializerDefineProperty(this, "vendor_uuid", _descriptor6, this);

      _initializerDefineProperty(this, "photo_uuid", _descriptor7, this);

      _initializerDefineProperty(this, "signup_token_uuid", _descriptor8, this);

      _initializerDefineProperty(this, "current_job_uuid", _descriptor9, this);

      _initializerDefineProperty(this, "vehicle_id", _descriptor10, this);

      _initializerDefineProperty(this, "vendor_id", _descriptor11, this);

      _initializerDefineProperty(this, "current_job_id", _descriptor12, this);

      _initializerDefineProperty(this, "user", _descriptor13, this);

      _initializerDefineProperty(this, "fleets", _descriptor14, this);

      _initializerDefineProperty(this, "jobs", _descriptor15, this);

      _initializerDefineProperty(this, "vehicle", _descriptor16, this);

      _initializerDefineProperty(this, "photo", _descriptor17, this);

      _initializerDefineProperty(this, "current_job", _descriptor18, this);

      _initializerDefineProperty(this, "vendor", _descriptor19, this);

      _initializerDefineProperty(this, "name", _descriptor20, this);

      _initializerDefineProperty(this, "phone", _descriptor21, this);

      _initializerDefineProperty(this, "email", _descriptor22, this);

      _initializerDefineProperty(this, "password", _descriptor23, this);

      _initializerDefineProperty(this, "photo_url", _descriptor24, this);

      _initializerDefineProperty(this, "vehicle_name", _descriptor25, this);

      _initializerDefineProperty(this, "vehicle_avatar", _descriptor26, this);

      _initializerDefineProperty(this, "vendor_name", _descriptor27, this);

      _initializerDefineProperty(this, "drivers_license_number", _descriptor28, this);

      _initializerDefineProperty(this, "location", _descriptor29, this);

      _initializerDefineProperty(this, "heading", _descriptor30, this);

      _initializerDefineProperty(this, "country", _descriptor31, this);

      _initializerDefineProperty(this, "city", _descriptor32, this);

      _initializerDefineProperty(this, "status", _descriptor33, this);

      _initializerDefineProperty(this, "online", _descriptor34, this);

      _initializerDefineProperty(this, "deleted_at", _descriptor35, this);

      _initializerDefineProperty(this, "created_at", _descriptor36, this);

      _initializerDefineProperty(this, "updated_at", _descriptor37, this);

      _initializerDefineProperty(this, "hasInvalidCoordinates", _descriptor38, this);
    }

    get updatedAgo() {
      return (0, _dateFns.formatDistanceToNow)(this.updated_at);
    }

    get updatedAt() {
      return (0, _dateFns.format)(this.updated_at, 'PPP p');
    }

    get updatedAtShort() {
      return (0, _dateFns.format)(this.updated_at, 'PP');
    }

    get createdAgo() {
      return (0, _dateFns.formatDistanceToNow)(this.created_at);
    }

    get createdAt() {
      return (0, _dateFns.format)(this.created_at, 'PPP p');
    }

    get createdAtShort() {
      return (0, _dateFns.format)(this.created_at, 'PP');
    }

    get latitude() {
      if (this.location) {
        let x = Ember.get(this.location, 'coordinates.0');
        let y = Ember.get(this.location, 'coordinates.1');
        return (0, _isLatitude.default)(x) ? x : y;
      }

      return 0;
    }

    get longitude() {
      if (this.location) {
        let x = Ember.get(this.location, 'coordinates.0');
        let y = Ember.get(this.location, 'coordinates.1');
        return (0, _isLongitude.default)(y) ? y : x;
      }

      return 0;
    }

    get coordinates() {
      // eslint-disable-next-line ember/no-get
      return [Ember.get(this, 'latitude'), Ember.get(this, 'longitude')];
    }

    get latlng() {
      return {
        // eslint-disable-next-line ember/no-get
        lat: Ember.get(this, 'latitude'),
        // eslint-disable-next-line ember/no-get
        lng: Ember.get(this, 'longitude')
      };
    }

    get latitudelongitude() {
      return {
        // eslint-disable-next-line ember/no-get
        latitude: Ember.get(this, 'latitude'),
        // eslint-disable-next-line ember/no-get
        longitude: Ember.get(this, 'longitude')
      };
    }

    get hasValidCoordinates() {
      return (0, _isValidCoordinates.default)(this.coordinates);
    }

    get activeJobs() {
      return this.jobs.filter(order => !['completed', 'canceled'].includes(order.status));
    }
    /** @methods */


    toJSON() {
      return (0, _autoSerialize.default)(this, ['fleets', 'jobs', 'current_job', 'user', 'vehicle', 'vendor']);
    }

    loadVehicle() {
      const owner = Ember.getOwner(this);
      const store = owner.lookup(`service:store`);
      return new Promise(resolve => {
        if ((0, _isRelationMissing.default)(this, 'vehicle')) {
          return store.findRecord('vehicle', this.vehicle_uuid).then(vehicle => {
            this.vehicle = vehicle;
            resolve(vehicle);
          }).catch(() => {
            resolve(null);
          });
        }

        resolve(this.vehicle);
      });
    }

    loadVendor() {
      const owner = Ember.getOwner(this);
      const store = owner.lookup(`service:store`);
      return new Promise(resolve => {
        if ((0, _isRelationMissing.default)(this, 'vendor')) {
          return store.findRecord('vendor', this.vendor_uuid).then(vendor => {
            this.vendor = vendor;
            resolve(vendor);
          }).catch(() => {
            resolve(null);
          });
        }

        resolve(this.vendor);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "public_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "company_uuid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user_uuid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "vehicle_uuid", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "vendor_uuid", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "photo_uuid", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "signup_token_uuid", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "current_job_uuid", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "vehicle_id", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "vendor_id", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "current_job_id", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "fleets", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "jobs", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "vehicle", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "photo", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "current_job", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "password", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "photo_url", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "vehicle_name", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "vehicle_avatar", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "vendor_name", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "drivers_license_number", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "heading", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "city", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "online", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec38], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec39], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAtShort", [_dec40], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAtShort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec41], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec42], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAtShort", [_dec43], Object.getOwnPropertyDescriptor(_class.prototype, "createdAtShort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "latitude", [_dec44], Object.getOwnPropertyDescriptor(_class.prototype, "latitude"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "longitude", [_dec45], Object.getOwnPropertyDescriptor(_class.prototype, "longitude"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "coordinates", [_dec46], Object.getOwnPropertyDescriptor(_class.prototype, "coordinates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "latlng", [_dec47], Object.getOwnPropertyDescriptor(_class.prototype, "latlng"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "latitudelongitude", [_dec48], Object.getOwnPropertyDescriptor(_class.prototype, "latitudelongitude"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasValidCoordinates", [_dec49], Object.getOwnPropertyDescriptor(_class.prototype, "hasValidCoordinates"), _class.prototype), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "hasInvalidCoordinates", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "activeJobs", [_dec51], Object.getOwnPropertyDescriptor(_class.prototype, "activeJobs"), _class.prototype)), _class));
  _exports.default = Driver;
});