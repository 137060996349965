define("@fleetbase/console/utils/extract-longitude", ["exports", "@fleetbase/console/utils/extract-coordinates", "terraformer"], function (_exports, _extractCoordinates, _terraformer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = extractLongitude;

  /* eslint-disable no-unused-vars */
  function extractLongitude(position) {
    let latitude, longitude;

    if (!position) {
      return 0;
    }

    if (position instanceof _terraformer.default.Point || Ember.isArray(position.coordinates)) {
      [latitude, longitude] = (0, _extractCoordinates.default)(position.coordinates);
      return longitude;
    }

    if (typeof position === 'object') {
      let longitude = position['lng'] || position['longitude'] || position['lon'] || position['y'];
      return longitude;
    }

    [latitude, longitude] = (0, _extractCoordinates.default)(position);
    return longitude;
  }
});