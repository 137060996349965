define("@fleetbase/console/validations/onboard-hello", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @validations onboard-hello */
  var _default = {
    name: [(0, _validators.validatePresence)(true)],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })],
    organization_name: [(0, _validators.validatePresence)(true)],
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 8
    })],
    password_confirmation: [(0, _validators.validatePresence)(true), (0, _validators.validateConfirmation)({
      on: 'password'
    })]
  };
  _exports.default = _default;
});