define("@fleetbase/console/helpers/hex-color-generator", ["exports", "fleet-ops/helpers/hex-color-generator"], function (_exports, _hexColorGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _hexColorGenerator.default;
    }
  });
  Object.defineProperty(_exports, "hexColorGenerator", {
    enumerable: true,
    get: function () {
      return _hexColorGenerator.hexColorGenerator;
    }
  });
});