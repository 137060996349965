define("@fleetbase/console/utils/lazy-load-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lazyLoadScript;

  function lazyLoadScript(path) {
    return new Promise(function (resolve, reject) {
      const id = Ember.guidFor(path);

      if (document.getElementById(id)) {
        return resolve();
      }

      const element = document.createElement('script');
      element.id = id;
      element.src = path;
      element.addEventListener('load', function () {
        resolve();
      });
      element.addEventListener('error', function () {
        reject(`Failed to load script (${path})`);
      });
      document.getElementsByTagName('head')[0].appendChild(element);
    });
  }
});