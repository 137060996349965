define("@fleetbase/console/adapters/store", ["exports", "storefront/adapters/storefront"], function (_exports, _storefront) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _storefront.default;
    }
  });
});