define("@fleetbase/console/utils/is-relation-missing", ["exports", "@fleetbase/console/utils/isset", "@fleetbase/console/utils/is-model"], function (_exports, _isset, _isModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isRelationMissing;

  function isRelationMissing(model, relation) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    if (!(0, _isModel.default)(model)) {
      return false;
    }

    relation = Ember.String.underscore(relation);
    const isMissingRelation = (0, _isset.default)(model, `${relation}_uuid`) && !(0, _isset.default)(model, ``);
    const isNotMissingPolymorphicType = (0, _isset.default)(model, `${relation}_type`);

    if ((0, _isset.default)(options, 'polymorphic') && options.polymorphic === true) {
      // no relation is set, so false
      return isNotMissingPolymorphicType && (0, _isset.default)(model, `${relation}_uuid`);
    }

    return isMissingRelation;
  }
});