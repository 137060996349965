define("@fleetbase/console/utils/extract-coordinates", ["exports", "@fleetbase/console/utils/is-latitude", "@fleetbase/console/utils/is-longitude"], function (_exports, _isLatitude, _isLongitude) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = extractCoordinates;

  function extractCoordinates() {
    let coordinates = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'latlng';
    let latitude = 0,
        longitude = 0;

    for (let i = 0; i < coordinates.length; i++) {
      let coord = coordinates[i];

      if ((0, _isLatitude.default)(coord)) {
        latitude = coord;
        continue;
      }

      if ((0, _isLongitude.default)(coord)) {
        longitude = coord;
        continue;
      }
    }

    if (format === 'lnglat') {
      return [longitude, latitude];
    }

    return [latitude, longitude];
  }
});