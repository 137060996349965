define("@fleetbase/console/utils/serialize/normalize-polymorphic-type-within-hash", ["exports", "@fleetbase/console/utils/serialize/normalize-polymorphic-type"], function (_exports, _normalizePolymorphicType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeNormalizePolymorphicTypeWithinHash;

  function serializeNormalizePolymorphicTypeWithinHash(hash) {
    if (typeof hash !== 'object') {
      return hash;
    }

    for (let attr in hash) {
      if (typeof attr === 'string' && attr.includes('_type') && encodeURI(hash[attr]).includes('%5C')) {
        const emberPolymorphicType = (0, _normalizePolymorphicType.default)(hash[attr]);
        const polymorphicRelationType = attr.replace(`_type`, ``);
        hash[attr] = emberPolymorphicType; // set the type of relationship using the polymorphic relation attr

        if (hash[polymorphicRelationType] && !Ember.isBlank(hash[polymorphicRelationType])) {
          if (hash[polymorphicRelationType].type) {
            Ember.set(hash, `${polymorphicRelationType}._type`, hash[polymorphicRelationType].type);
          }

          Ember.set(hash, `${polymorphicRelationType}.type`, emberPolymorphicType);
        }
      }
    }

    return hash;
  }
});