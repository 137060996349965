define("@fleetbase/console/controllers/console/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ConsoleHomeController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class ConsoleHomeController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);
    }

    get user() {
      return this.currentUser.user;
    }

    verifyEmail() {
      this.modalsManager.show('modals/verify-email', {
        title: 'Verify your Email',
        acceptButtonText: 'Send',
        email: this.currentUser.email,
        sent: false,
        code: null,
        exitOverride: true,
        confirm: async (modal, done) => {
          modal.startLoading();
          const email = modal.getOption('email');
          const code = modal.getOption('code');
          const sent = modal.getOption('sent'); // if sent -- verify

          if (sent) {
            return this.fetch.post('onboard/verify-email', {
              code,
              session: this.currentUser.id
            }).then(response => {
              if (response.status === 'success') {
                this.notifications.success('Email successfully verified!');
                this.currentUser.user.email_verified_at = response.verified_at;
                return done();
              }
            }).catch(error => {
              this.notifications.serverError(error);
              this.modalsManager.setOption('code', '');
            });
          } // if not sent -- send


          await this.fetch.post('onboard/send-verification-email', {
            email
          }).then(() => {
            this.notifications.success('Verification email sent.');
          });
          this.modalsManager.setOption('sent', true);
          modal.stopLoading();
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "verifyEmail", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "verifyEmail"), _class.prototype)), _class));
  _exports.default = ConsoleHomeController;
});